<template>
  <div>
    <router-link  :to="{name: 'IntegrationPartners'}">
      <div class="flex items-center mb-6">
        <feather-icon class="text-primary" icon="ArrowLeftIcon"></feather-icon>
        <h6 class="text-primary">Integration Partners</h6>
      </div>
    </router-link>
  
    <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none setting-tabs" id="profile-tabs" :key="isSmallerScreen">
      <!-- CRM Integration Options -->
      <!--General Settings -->
      <vs-tab label="General Settings">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">General Settings</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>{{ generalSettingpageText }}</p>
                </div>
                <div class="common-table p-4">
                  <form data-vv-scope="senderForm">
                    <div class="vx-row">
                      <div class="vx-col md:w-1/3 mb-6 w-full">
                        <vs-input class="w-full" label="Connection Name" name="connectionName" v-model="connectionName"/>
                      </div>
                      <div class="vx-col flex items-center mb-6 w-full">
                        <span class="vs-input--label pr-4 float-left">Integration Partner:</span>
                        <p class="mr-4">{{ integrationPartnerName?integrationPartnerName:'' }}</p>
                        <vs-button
                          @click="connectionStatus?disconnectCRM():connectCRM()"
                          title="Update"
                          color="warning"
                          type="filled"
                          class="mr-2"
                          >{{ connectionStatus?'Disconnect':'Connect' }}</vs-button
                        >
                      </div>
                      <div class="vx-col flex items-center mb-6 w-full">
                        <span class="vs-input--label pr-4 float-left">Connection Status:</span>
                        <p class="mr-4" :class="connectionStatus?'text-success':'text-warning'"> {{ connectionStatus?'Connected':'Disconnected' }}</p>
                      </div>
                      <div class="vx-col flex items-center mb-6 w-full">
                        <vs-checkbox class="ml-0" v-model="limitDailyAPICalls">Limit Daily API calls to</vs-checkbox>
                        <vs-input v-validate="'numeric'" name="dailyAPICalls" v-model="dailyAPICalls" :disabled="!limitDailyAPICalls" />
                      </div>
                      <div class="vx-col flex items-center mb-6 w-full">
                        <vs-checkbox class="ml-0" v-model="limitMaxSyncRecords">Limit maximum sync records to</vs-checkbox>
                        <vs-input name="maxSyncRecords" v-validate="'numeric'" v-model="maxSyncRecords" :disabled="!limitMaxSyncRecords" />
                      </div>
                      <div class="vx-col mb-6 w-full">
                        <vs-checkbox class="ml-0" v-model="allowReadAccessFromIntegrationPartner" :disabled="allowReadAccessFromIntegrationPartner">
                          Allow VisualVisitor to read data from your system
                        </vs-checkbox>
                      </div>
                      <div class="vx-col mb-6 w-full">
                        <vs-checkbox class="ml-0" v-model="allowWriteAccessIntoIntegrationPartner">
                          Allow VisualVisitor to update and insert new records into your system
                        </vs-checkbox>
                      </div>
                      <div class="vx-col mb-6 w-full">
                        <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveGeneralSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!--CRM Data Filter  -->
      <vs-tab label="CRM Data Filter">
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">CRM Data Filter</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p> 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table mt-4  w-full">
                  <div class="flex btn-search w-full">
                    <h5 class="mb-2 ml-4">CRM Export Filters</h5>
                  </div>
                  <div class="responsive-table">
                    <table pagination class="table w-full  comparision-table">
                      <thead>
                        <th>Enabled</th>
                        <th>Module</th>
                        <th>Filter Name</th>
                        <th>Scope</th>
                        <th>Created By </th>
                        <th>Actions</th>
                      </thead>
                      <draggable v-model="crmFilterData" tag="tbody" @start="drag = true" >
                        <tr :data="tr" :key="indextr" v-for="(tr, indextr) in crmFilterData">
                          <td> <vs-checkbox v-model="crmFilterData[indextr].Enabled"> </vs-checkbox></td>
                          <td :data="crmFilterData[indextr].Module">{{ crmFilterData[indextr].Module }}</td>
                          <td :data="crmFilterData[indextr].Filter_Name">{{ crmFilterData[indextr].Filter_Name }}</td>
                          <td :data="crmFilterData[indextr].Scope">{{ crmFilterData[indextr].Scope }}</td>
                          <td :data="crmFilterData[indextr].Created_By">{{ crmFilterData[indextr].Created_By }}</td>
                          <td>
                            <div class="table-action w-full ">
                              <feather-icon class="mr-1 mt-3 mb-1 float-right"  icon="MoveIcon" ></feather-icon>
                              <!-- <feather-icon class="mr-1 float-right" icon="ArrowDownIcon"></feather-icon> -->
                              <!-- <feather-icon class="mr-1 float-right" icon="ArrowUpIcon"></feather-icon> -->
                              <vs-button
                                title="Copy"
                                color="warning"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-copy"
                                class="mr-1 my-1 float-right"
                                @click="getCRMAvailableFields(tr,'copy')"
                              ></vs-button>
                              <vs-button
                                v-if="crmFilterData[indextr].Scope != 'System'"
                                title="Delete"
                                color="danger"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-minus"
                                class="mr-1 my-1 float-right"
                                @click="deleteFilterConfirmation(crmFilterData[indextr])"
                              ></vs-button>
                              <vs-button
                                title="Plus"
                                color="success"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-plus"
                                class="mr-1 my-1 float-right"
                                @click="getCRMAvailableFields(tr,'add')"
                              ></vs-button>
                              <vs-button
                                @click="getCRMAvailableFields(tr,'edit')"
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1 my-1 float-right"
                              ></vs-button>
                            </div>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                  <div class="float-left my-3  ml-4 w-full">
                    <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveFilterSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Account Mapping -->
      <vs-tab label="Account Mapping">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">VisualVisitor to CRM {{ CRMModuleText}} Mapping</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p> 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table ">
                  <div class="flex btn-search w-full px-4">
                    <h5 class="mb-2">{{CRMModuleText}} Mapping</h5>
                  </div>
                  <!-- table -->
                  <vs-table class="account-table" :data="accountMapCRMAvailableFields">
                    <template slot="thead">
                      <vs-th>ID Field</vs-th>
                      <vs-th>CRM Field Name</vs-th>
                      <vs-th>VisualVisitor Field</vs-th>
                      <vs-th>Validation Rules</vs-th>
                      <vs-th>Default Value</vs-th>
                      <vs-th>Overwrite</vs-th>
                      <vs-th>Example Data</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <vs-radio  :disabled="!tr.Update_Field" v-model="keyFieldAccount" :vs-value="tr.Field_API_Name" vs-name="keyFieldAccount"></vs-radio>
                          <!-- <vs-input type="radio" :disabled="tr.Field_API_Name && !tr.Field_API_Name.Update_Field" /> -->
                        </vs-td>
                        <!-- <vs-td :class="tr.Field_API_Name && tr.Field_API_Name.Required_Field?'text-danger':''">
                          <span v-if="tr.Field_API_Name && tr.Field_API_Name.Required_Field">*</span>{{ tr.VV_Field_Display_Name}}
                        </vs-td> -->
                        <vs-td :class="tr.Required_Field?'text-danger':''"><span v-if="tr.Required_Field">*</span>{{ tr.Field_Display_Name }}</vs-td>
                        <vs-td>
                          <v-select
                            :disabled="tr.System_Field"
                            :options="accountMapVVFieldList"
                            label="VV_Field_Display_Name"
                            v-model="tr.CurrentRowValues.VV_Field_Id"
                            :selectable="(option) => !accountCrmSelected.includes(option.VV_Field_Id)"
                            @input="updateAccountFieldMapValues(indextr)"
                          />
                          <span class="text-danger text-sm" v-if="errorArrayAccount &&errorArrayAccount.vvField.includes(indextr)">Please select visual visitor field</span>
                        </vs-td >
                        <vs-td>
                          <v-select
                            :disabled="tr.System_Field"
                            v-model="tr.CurrentRowValues.Validation_Rule_Id"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="tr.Available_Validation_Rules"
                          />
                          <span class="text-danger text-sm" v-if="errorArrayAccount &&errorArrayAccount.ValidationRule.includes(indextr)">Please select validation rule</span>
                        </vs-td>
                        <vs-td>
                          <vs-input :disabled="tr.System_Field" v-model="tr.CurrentRowValues.Default_Value" class="w-auto" placeholder="-- None --" />
                          <!-- <span class="text-danger text-sm" v-if="errorArrayAccount &&errorArrayAccount.DefaulValue.includes(indextr)">Please enter default value</span> -->
                        </vs-td>
                        <vs-td> 
                          <vs-checkbox v-model="tr.CurrentRowValues.Overwrite" :disabled="tr.System_Field"> </vs-checkbox>
                        </vs-td>
                        <vs-td class="text-muted"> {{ tr.CurrentRowValues.VV_Field_Id?tr.CurrentRowValues.VV_Field_Id.Example:"" }} </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div class="w-full mt-4 float-left">
                    <vs-checkbox v-model="accountMapUpdateBlankFields">Do not update blank fields. </vs-checkbox>
                  </div>
                  <div class="my-4 w-full float-left">
                    <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveAccountMapSetting()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Contact Mapping -->
      <vs-tab label="Contact Mapping">
        <div class="tab-social-links md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">VisualVisitor to CRM {{ CRMModuleText }} Mapping</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table ">
                  <div class="flex btn-search w-full px-4">
                    <h5 class="mb-2">{{ CRMModuleText }} Mapping</h5>
                  </div>
                  <!-- table -->
                  <vs-table class="account-table" :data="contactMapCRMAvailableFields">
                    <template slot="thead">
                      <vs-th>ID Field</vs-th>
                      <vs-th>CRM Field Name</vs-th>
                      <vs-th>VisualVisitor</vs-th>
                      <vs-th>Validation Rules</vs-th>
                      <vs-th>Default Value</vs-th>
                      <vs-th>Overwrite</vs-th>
                      <vs-th>Example Data</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <vs-radio  :disabled="!tr.Update_Field" v-model="keyFieldContact" :vs-value="tr.Field_API_Name" vs-name="keyFieldContact"></vs-radio>
                          <!-- <vs-input type="radio" :disabled="tr.Field_API_Name && !tr.Field_API_Name.Update_Field" /> -->
                        </vs-td>
                        <vs-td :class="data[indextr].Required_Field ? 'text-danger' : ''">
                          <span v-if="data[indextr].Required_Field">*</span>{{ data[indextr].Field_Display_Name }}
                        </vs-td>
                        <vs-td>
                          <v-select
                            :options="contactMapVVFieldList"
                            label="VV_Field_Display_Name"
                            v-model="tr.CurrentRowValues.VV_Field_Id"
                            :selectable="(option) => !contactCrmSelected.includes(option.VV_Field_Id)"
                            @input="updateContactFieldMapValues(indextr)"
                            :disabled="tr.System_Field"
                          />
                          <span class="text-danger text-sm" v-if="!data[indextr].CurrentRowValues.VV_Field_Id && errorArrayContact && errorArrayContact.vvField.includes(indextr)">
                            Please select visual visitor field
                          </span>
                        </vs-td>
                        <vs-td>
                          <v-select
                            v-model="tr.CurrentRowValues.Validation_Rule_Id"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="tr.Available_Validation_Rules"
                            :disabled="tr.System_Field"
                          />
                          <span class="text-danger text-sm" v-if="errorArrayContact &&errorArrayContact.ValidationRule.includes(indextr)">Please select validation rule</span>
                        </vs-td>
                        <vs-td>
                          <vs-input
                            v-model="tr.CurrentRowValues.Default_Value"
                            class="w-auto"
                            placeholder="-- None --"
                            :disabled="tr.System_Field"
                          />
                          <!-- <span class="text-danger text-sm" v-if="errorArrayContact &&errorArrayContact.DefaulValue.includes(indextr)">Please enter default value</span> -->
                        </vs-td>
                        <vs-td> 
                          <vs-checkbox v-model="tr.CurrentRowValues.Overwrite" :disabled="tr.System_Field"> </vs-checkbox>
                        </vs-td>
                        <vs-td class="text-muted"> {{ tr.CurrentRowValues.VV_Field_Id ? tr.CurrentRowValues.VV_Field_Id.Example : "" }} </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div class="w-full mt-4 float-left">
                    <vs-checkbox v-model="contactMapUpdateBlankFields">Do not update blank fields. </vs-checkbox>
                  </div>
                  <div class="my-4 w-full float-left">
                    <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveContactMapSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Lead Mapping -->
      <vs-tab label="Lead Mapping">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">VisualVisitor to CRM {{ CRMModuleText }} Mapping</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="w-full flex justify-between flex-wrap float-left">
                  <!-- <vs-checkbox class="my-2 text-warning" v-model="leadUseContactMapping">Use {{ CRMModuleText }} Mappings</vs-checkbox> -->
                  <!-- <vs-button title="copy" color="warning" type="filled" class="my-2" icon-pack="feather" icon="icon-copy" @click="copyMappingFromContact()">
                    Copy From {{ CRMModuleText }}
                  </vs-button> -->
                </div>
                <div class="common-table p-4">
                  <div class="flex btn-search w-full">
                    <h5 class="mb-2">{{ CRMModuleText }} Mapping</h5>
                  </div>
                  <!-- table -->
                  <vs-table class="account-table" :data="leadMapCRMAvailableFields">
                    <template slot="thead">
                      <vs-th>ID Field</vs-th>
                      <vs-th>CRM Field Name</vs-th>
                      <vs-th>VisualVisitor</vs-th>
                      <vs-th>Validation Rules</vs-th>
                      <vs-th>Default Value</vs-th>
                      <vs-th>Overwrite</vs-th>
                      <vs-th>Example Data</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <vs-radio  :disabled="!tr.Update_Field" v-model="keyFieldLead" :vs-value="tr.Field_API_Name" vs-name="keyFieldLead"></vs-radio>
                          <!-- <vs-input type="radio" :disabled="tr.Field_API_Name && !tr.Field_API_Name.Update_Field" /> -->
                        </vs-td>
                        <vs-td :class="tr.Required_Field ? 'text-danger' : ''">
                          <span v-if="tr.Required_Field">*</span>{{ tr.Field_Display_Name }}
                        </vs-td>
                        <vs-td>
                          <v-select
                            :disabled="tr.System_Field"
                            :options="leadMapVVFieldList"
                            label="VV_Field_Display_Name"
                            v-model="tr.CurrentRowValues.VV_Field_Id"
                            :selectable="(option) => !leadCrmSelected.includes(option.VV_Field_Id)"
                            @input="updateLeadFieldMapValues(indextr)"
                          />
                          <span class="text-danger text-sm" v-if="!data[indextr].CurrentRowValues.VV_Field_Id && errorArrayLead && errorArrayLead.vvField.includes(indextr)">
                            Please select visual visitor field
                          </span>
                        </vs-td>
                        <vs-td>
                          <v-select
                            :disabled="tr.System_Field"
                            v-model="tr.CurrentRowValues.Validation_Rule_Id"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="tr.Available_Validation_Rules"
                          />
                          <span class="text-danger text-sm" v-if="errorArrayLead &&errorArrayLead.ValidationRule.includes(indextr)">Please select validation rule</span>
                        </vs-td>
                        <vs-td>
                          <vs-input v-model="tr.CurrentRowValues.Default_Value" class="w-auto" placeholder="-- None --" :disabled="tr.System_Field" />
                          <!-- <span class="text-danger text-sm" v-if="errorArrayLead &&errorArrayLead.DefaulValue.includes(indextr)">Please enter default value</span> -->
                        </vs-td>
                        <vs-td> 
                          <vs-checkbox v-model="tr.CurrentRowValues.Overwrite" :disabled="tr.System_Field"> </vs-checkbox>
                        </vs-td>
                        <vs-td class="text-muted"> {{ tr.CurrentRowValues.VV_Field_Id ? tr.CurrentRowValues.VV_Field_Id.Example : ""}} </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div class="w-full mt-4 float-left">
                    <vs-checkbox v-if="!leadUseContactMapping" v-model="leadMapUpdateBlankFields">Do not update blank fields. </vs-checkbox>
                  </div>
                  <div class="my-4 w-full float-left">
                    <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveLeadMapSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Global Lead Handling -->
      <vs-tab label="Global Lead Handling ">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">Global Lead Handling Preferences</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Preferences when importing data into your system</h5>
                  </div>
                  <!-- form 1 -->
                  <form data-vv-scope="senderForm" class="px-3">
                    <div class="vx-row">
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <div class="flex-1">
                          <span class="vs-input--label pr-4 float-left">
                            When no owner is found for an {{accountText}} the record should be assigned to:
                          </span>
                        </div>
                        <div class="flex-1">
                          <v-select
                            :clearable="false"
                            v-model="defaultAccountOwnerUserId"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="userList"
                          />
                        </div>
                      </div>
                      <div class="vx-col flex-wrap flex items-center mb-2 w-full">
                        <div class="flex-1">
                          <span class="vs-input--label pr-4 float-left">
                            When no owner is found for a {{ contactText }} the record should be assigned to:
                          </span>
                        </div>
                        <div class="flex-1">
                          <v-select
                            :clearable="false"
                            v-model="defaultContactOwnerUserId"
                            :reduce="options => options.Key"
                            label="Value"
                            class="w-full"
                            :options="userList"
                          />
                        </div>
                      </div>
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <div class="flex-1 my-1">
                          <span class="vs-input--label pr-4 float-left">
                            When no owner is found for an {{ leadText }} the record should be assigned to:
                          </span>
                        </div>
                        <div class="flex-1 my-1">
                          <v-select
                            :clearable="false"
                            v-model="defaultLeadOwnerUserId"
                            :reduce="options => options.Key"
                            label="Value"
                            class="w-full"
                            :options="userList"
                          />
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">When importing {{ accountText }} into your system:</span>
                        <div class="flex w-full flex-wrap items-center mt-2 px-3">
                          <div class="flex-1 my-1 pr-1" :key="indextr"  v-for="(importList, indextr) in importingIntoSystemList">
                            <vs-radio 
                              v-model="importToCRMAccountOption" 
                              :disabled="(importToCRMContactOption ==3 && importToCRMLeadOption==3)?false:true"
                              :vs-value="importList.Key" 
                              vs-name="importToCRMAccountOption"
                            >
                              {{importList.Value}}
                            </vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">When importing {{ contactText }} into your system:</span>
                        <div class="flex flex-wrap w-full items-center mt-2 px-3">
                          <div class="flex-1 my-1 pr-1" :key="indextr"  v-for="(importList, indextr) in importingIntoSystemList">
                            <vs-radio v-model="importToCRMContactOption" :vs-value="importList.Key" vs-name="importToCRMContactOption">{{importList.Value}}</vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">When importing {{ leadText }} into Salesforce:</span>
                        <div class="flex flex-wrap w-full items-center mt-2 px-3">
                          <div class="flex-1 my-1 pr-1"  :key="indextr"  v-for="(importList, indextr) in importingIntoSystemList">
                            <vs-radio v-model="importToCRMLeadOption" :vs-value="importList.Key" vs-name="importToCRMLeadOption">{{importList.Value}}</vs-radio>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">How to handle duplicate records?</span>
                        <div class="flex flex-wrap w-full items-center mt-2 px-3">
                          <div class="flex-1 my-1 pr-1"  :key="indextr"  v-for="(handlingList, indextr) in handlingDuplicateList">
                            <vs-radio v-model="importToCRMDuplicateHandlingOption" :vs-value="handlingList.Key" vs-name="importToCRMDuplicateHandlingOption">
                              {{ handlingList.Value }}
                            </vs-radio>
                          </div>
                        </div>
                      </div> -->
                      <div class="vx-col mb-6 w-full">
                        <span class="vs-input--label w-full">Do not import a {{ contactText }} or {{ leadText }} into Salesforce if the record is missing:</span>
                        <div class="flex flex-wrap w-full items-center mt-2 px-3">
                          <div class="my-1 pr-1" :key="indextr"  v-for="(notImport, indextr) in doNotImportList">
                            <vs-checkbox class="ml-0" v-model="doNotImport" :vs-value="notImport.Key" vs-name="doNotImport">{{ notImport.Value }}</vs-checkbox>
                          </div>
                        </div>
                      </div>                      
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <div class="">
                          <span class="vs-input--label pr-4">When import is completed send notification to:</span>
                        </div>
                        <div class=" lg:w-1/2 w-full">
                          <vs-input class="w-full" placeholder="example@visualvisitor.com, example1@visualvisitor.com" v-model="importNotificationEmail" name="importNotificationEmail" @blur="validateMultipleEmailsCommaSeparated()"/>
                          <span class="text-danger" v-if="!validateMultipleEmailsCommaSeparated()">Invalid Email Id</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="common-table p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Preferences when exporting data to VisualVisitor</h5>
                  </div>
                  <!-- form 1 -->
                  <form data-vv-scope="senderForm" class="px-3">
                    <div class="vx-row">
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">
                          When exporting {{ accountText }} data from your system and the record matches an {{ accountText }} record in VisualVisitor the:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 px-3">
                          <div class="w-full my-1" :key="indextr"  v-for="(exportOption, indextr) in exportingIntoSystemList">
                            <vs-radio v-model="exportToCRMAccountOption" :vs-value="exportOption.Key" vs-name="exportToCRMAccountOption">
                             {{ exportOption.Value }}
                            </vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">
                          When exporting {{ contactText }} data from your system and the record matches an {{ contactText }} record in VisualVisitor the:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 px-3">
                          <div class="w-full my-1" :key="indextr" v-for="(exportOption, indextr) in exportingIntoSystemList">
                            <vs-radio v-model="exportToCRMContactOption" :vs-value="exportOption.Key" vs-name="exportToCRMContactOption">
                              {{ exportOption.Value }}
                            </vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">
                          When exporting {{ leadText }} data from your system and the record matches an {{ leadText }} record in VisualVisitor the:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 px-3">
                          <div class="w-full my-1" :key="indextr" v-for="(exportOption, indextr) in exportingIntoSystemList">
                            <vs-radio v-model="exportToCRMLeadOption" :vs-value="exportOption.Key" vs-name="exportToCRMLeadOption">
                              {{ exportOption.Value }}
                            </vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <div class="">
                          <span class="vs-input--label pr-4">When import is completed send notification to:</span>
                        </div>
                        <div class=" lg:w-1/2 w-full ">
                          <vs-input class="w-full" v-model="exportNotificationEmail" name="exportNotificationEmail" placeholder="example@visualvisitor.com, example1@visualvisitor.com"  @blur="validateMultipleEmailsCommaSeparatedExport()" />
                          <span class="text-danger" v-if="!validateMultipleEmailsCommaSeparatedExport()">Invalid Email Id</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="vx-col mt-6 w-full float-left">
                  <vs-button title="Save" color="primary" type="filled" class="float-left" @click="saveGlobalLeadHandlingSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Module Lead Handling -->
      <vs-tab label="Module Lead Handling">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <form data-vv-scope="moduleHandlingform" class="px-3">
                <div class="vx-col xl:w-full col-cus-12 w-full">
                  <div class="card-title more-card-title">
                    <h2 class="mb-2">VisualVisitor Module Lead Handling Preferences</h2>
                    <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                      Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                    </p>
                  </div>
                  <div class="common-table mb-6 p-4">
                    <div class="flex w-full">
                      <h5 class="mb-6">Module - Who's Looking</h5>
                    </div>
                    <!-- form 1 -->
                    <!-- <form data-vv-scope="moduleLeadHandling" class="px-3"> -->
                    <div class="vx-row">
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="allowQuickExportLooking">Allow users to manually export into your system</vs-checkbox>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full pl-0">
                          When a new <span class="text-warning cursor-pointer">Export</span> is generated and the lead does not exist in {{ moduleCrmName }}:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 pl-8" v-for="(radioOption, index) in recordListOptions" :key="index">
                          <vs-radio      
                            v-model="lookingQuickExportOptionId"                 
                            name="lookingQuickExportOptionId"
                            :vs-name="'lookingQuickExportOptionId'"
                            class="my-1"
                            :vs-value="radioOption.Key"  
                            v-validate="'required'"        
                          >
                            {{ radioOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger pl-8 text-sm error-msg">{{errors.first("moduleHandlingform.lookingQuickExportOptionId")}}</span>
                      </div>
                      <div class="vx-col flex items-start mb-2 w-full">
                        <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                          Tag New <span class="text-warning cursor-pointer">Export</span> record as a 
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            label="Value"
                            :options="classificationsOptions"
                            :reduce="(option) => option.Key"
                            v-model="lookingQuickExportClassificationId"
                            name="lookingQuickExportClassificationId"
                            v-validate="'required'"
                          />
                          <span class="text-danger text-sm error-msg">{{errors.first("moduleHandlingform.lookingQuickExportClassificationId")}}</span>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full">
                          When a new <span class="text-warning cursor-pointer">Form Capture</span> is generated and the lead does not exist in {{moduleCrmName}}:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 px-3">
                          <div class="w-full my-1" :key="indextr" v-for="(formOptions, indextr) in recordListOptions">
                            <vs-radio v-model="formCaptureOptionId" :vs-value="formOptions.Key" vs-name="formCaptureOptionId">{{ formOptions.Value }}</vs-radio>
                          </div>
                        </div>
                      </div>
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <span class="vs-input--label pr-4 float-left">
                          Tag New <span class="text-warning cursor-pointer">Form Capture</span> as a
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            v-model="formCaptureClassificationId"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="classificationsOptions"
                          />
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="formCaptureInsertNote">
                          Add a note to the Record in {{ moduleCrmName }} when a new <span class="text-warning cursor-pointer">Form Capture</span> is generated.
                        </vs-checkbox>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="shoppingOptionEnabled">
                          When an <span class="text-warning cursor-pointer">Identified Website Visitor</span> has:
                        </vs-checkbox>
                      </div>
                      <div class="vx-col flex flex-wrap items-top w-full">
                        <span class="vs-input--label pr-2 float-left pt-4">Page visits is greater than</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="webVisitorOptionVisitThreshold"  class="w-auto" v-validate="'numeric'" type="text" name="webVisitorOptionVisitThreshold" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.webVisitorOptionVisitThreshold")}}</span>
                        </div>
                        <span class="vs-input--label pr-2 pt-4 float-left">within a period of</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="webVisitorOptionVisitPeriod" class="w-auto" v-validate="'numeric'" type="text" name="webVisitorOptionVisitPeriod" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.webVisitorOptionVisitPeriod")}}</span>
                        </div>
                        <span class="vs-input--label pr-2 pt-4 float-left">and the visitor does not exist in your system:</span>
                      </div>
                      <div class=" flex w-full flex-d-column justify-start mt-2 px-3">
                        <div class="w-full my-1" :key="indextr" v-for="(webOptions, indextr) in recordListOptions">
                          <vs-radio v-model="webVisitorOptionId" :vs-value="webOptions.Key" vs-name="webVisitorOptionId">{{ webOptions.Value }}</vs-radio>
                        </div>
                      </div>
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <span class="vs-input--label pr-4 float-left">
                          Tag new <span class="text-warning cursor-pointer">Identified Website Visitor</span> as a
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            v-model="webVisitorClassificationId"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="classificationsOptions"
                          />
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="webVisitorInsertNote" >
                          Add a note to the record in your system when an <span class="text-warning cursor-pointer">Identified Website Visitor</span> has:
                        </vs-checkbox>
                      </div>
                      <div class="vx-col flex flex-wrap items-top w-full">
                        <span class="vs-input--label pr-2 pt-4 float-left">Page visits is greater than</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="webVisitorNoteOptionVisitThreshold" class="w-auto" type="text" v-validate="'numeric'" name="webVisitorNoteOptionVisitThreshold" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.webVisitorNoteOptionVisitThreshold")}}</span>
                        </div>
                        <span class="vs-input--label pr-2 pt-4 float-left">within a period of</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="webVisitorNoteOptionVisitPeriod" class="w-auto" v-validate="'numeric'" type="text" name="webVisitorNoteOptionVisitPeriod" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.webVisitorNoteOptionVisitPeriod")}}</span>
                        </div>
                        <span class="vs-input--label pt-4 pr-2 float-left">days.</span>
                      </div>
                    </div>
                    <!-- </form> -->
                  </div>
                  <div class="common-table mb-6 p-4">
                    <div class="flex w-full">
                      <h5 class="mb-6">Module - Who's Shopping</h5>
                    </div>
                    <!-- form 1 -->
                    <!-- <form data-vv-scope="moduleHandlingform" class="px-3"> -->
                    <div class="vx-row">
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="allowQuickExportShopping">Allow users to manually export into your system</vs-checkbox>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full pl-0">
                          When a new <span class="text-warning cursor-pointer">Export</span> is generated and the shopper does not exist in {{ moduleCrmName }}:
                        </span>
                        <div class="flex w-full flex-d-column justify-start mt-2 pl-8" v-for="(shoppingExportOption, index) in recordListOptions" :key="index">
                          <vs-radio                       
                            class="my-1"
                            v-model="shoppingQuickExportOptionId"
                            :vs-name="'shoppingQuickExportOptionId'"
                            name="shoppingQuickExportOptionId"
                            :vs-value="shoppingExportOption.Key"  
                            v-validate="'required'"                
                          >
                            {{ shoppingExportOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger pl-8 text-sm error-msg">{{errors.first("moduleHandlingform.shoppingQuickExportOptionId")}}</span>
                      </div>
                      <div class="vx-col flex items-start mb-2 w-full">
                        <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                          Tag New <span class="text-warning cursor-pointer">Export</span> record as a 
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            label="Value"
                            :options="classificationsOptions"
                            :reduce="(option) => option.Key"
                            v-model="shoppingQuickExportClassificationId"
                            name="shoppingQuickExportClassificationId"
                            v-validate="'required'"
                          />
                          <span class="text-danger text-sm error-msg">{{errors.first("moduleHandlingform.shoppingQuickExportClassificationId")}}</span>
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="shoppingOptionEnabled">
                          When an identified <span class="text-warning cursor-pointer">Shopping Company</span> has:
                        </vs-checkbox>
                      </div>
                      <div class="vx-col flex flex-wrap items-top w-full">
                        <span class="vs-input--label pr-2 pt-4 float-left">Score greater than</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="shoppingOptionScoreThreshold" class="w-auto" v-validate="'numeric'" name="shoppingOptionScoreThreshold" type="text" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.shoppingOptionScoreThreshold")}}</span>
                        </div>
                        <span class="vs-input--label pt-4 pr-2 float-left">within a period of</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="shoppingOptionScorePeriod" class="w-auto" v-validate="'numeric'" name="shoppingOptionScorePeriod" type="text" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.shoppingOptionScorePeriod")}}</span>
                        </div>
                        <span class="vs-input--label pr-2 pt-4 float-left">and the shopper does not exist in your system:</span>
                      </div>
                      <div class=" flex w-full flex-d-column justify-start mt-2 px-3">
                        <div class="w-full my-1" :key="indextr" v-for="(shoppingOptions, indextr) in recordListOptions">
                          <vs-radio v-model="shoppingOptionId" :vs-value="shoppingOptions.Key" vs-name="shoppingOptionId">{{ shoppingOptions.Value }}</vs-radio>
                        </div>
                      </div>
                      <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                        <span class="vs-input--label pr-4 float-left">
                          Tag new <span class="text-warning cursor-pointer">Shopping Company</span> employees as a
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            v-model="shoppingClassificationId"
                            :reduce="options => options.Key"
                            label="Value"
                            :options="classificationsOptions"
                          />
                        </div>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <vs-checkbox class="ml-0" v-model="shoppingInsertNote">Add a note to the record in your system when an
                          <span class="text-warning cursor-pointer">Shopping Company</span>has:
                        </vs-checkbox>
                      </div>
                      <div class="vx-col flex flex-wrap items-top w-full">
                        <span class="vs-input--label pr-2 pt-4 float-left">Score greater than</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="shoppingNoteOptionScoreThreshold" class="w-auto" name="shoppingNoteOptionScoreThreshold" v-validate="'numeric'" type="text" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.shoppingNoteOptionScoreThreshold")}}</span>
                        </div>
                        <span class="vs-input--label pr-2 pt-4 float-left">within a period of</span>
                        <div class="md:w-1/6 pr-2 w-full my-2">
                          <vs-input v-model="shoppingNoteOptionScorePeriod" class="w-auto" v-validate="'numeric'" name="shoppingNoteOptionScorePeriod" type="text" />
                          <span class="text-danger text-sm">{{errors.first("moduleHandlingform.shoppingNoteOptionScorePeriod")}}</span>
                        </div>
                        <span class="vs-input--label pt-4 pr-2 float-left">days.</span>
                      </div>
                    </div>
                    <!-- </form> -->
                  </div>
                  <div class="common-table mb-6 p-4">
                    <div class="flex w-full">
                      <h5 class="mb-6">Module - Who To Contact</h5>
                    </div>
                    <!-- form 1 -->
                    <!-- <form data-vv-scope="moduleContactform" class="px-3"> -->
                    <div class="vx-row">
                      <div class="vx-col mb-2 w-full">
                          <vs-checkbox class="ml-0" v-model="allowQuickExportContact">Allow users to manually export into your system</vs-checkbox>
                      </div>
                      <div class="vx-col mb-2 w-full">
                        <span class="vs-input--label w-full pl-0">
                          When a new <span class="text-warning cursor-pointer">Export</span> is generated and the contact does not exist in {{ moduleCrmName }}:
                        </span>
                        <div class=" flex w-full flex-d-column justify-start mt-2 pl-8" v-for="(moduleExportOption, index) in recordListOptions" :key="index">
                          <vs-radio                       
                            v-model="contactQuickExportOptionId"
                            name="contactQuickExportOptionId"
                            :vs-name="'contactQuickExportOptionId'"
                            class="my-1"
                            :vs-value="moduleExportOption.Key"   
                            v-validate="'required'"               
                          >
                            {{ moduleExportOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger pl-8 text-sm error-msg">{{errors.first("moduleHandlingform.contactQuickExportOptionId")}}</span>
                      </div>
                      <div class="vx-col flex items-start mb-2 w-full">
                        <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                          Tag New <span class="text-warning cursor-pointer">Export</span> record as a 
                        </span>
                        <div class="md:w-1/3 pr-4 w-full my-2">
                          <v-select
                            label="Value"
                            :options="classificationsOptions"
                            :reduce="(option) => option.Key"
                            v-model="contactQuickExportClassificationId"
                            name="contactQuickExportClassificationId"
                            v-validate="'required'"
                          />
                          <span class="text-danger text-sm error-msg">{{errors.first("moduleHandlingform.contactQuickExportClassificationId")}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- </form> -->
                  </div>
                  <div class="common-table mb-6 p-4">
                    <div class="flex w-full">
                      <h5 class="mb-6">Module - Call Tracking</h5>
                    </div>
                    <!-- form 1 -->
                    <form data-vv-scope="senderForm" class="px-3">
                      <div class="vx-row">
                        <div class="vx-col mb-2 w-full">
                          <vs-checkbox class="ml-0" v-model="callTrackingInsertNote">Add a note to the {{ moduleCrmName }} record with a matching phone number</vs-checkbox>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="vx-col mt-6 w-full float-left">
                    <vs-button
                      title="Save"
                      color="primary"
                      type="filled"
                      class="float-left"
                      @click="saveSettingModuleLeadHandling()"
                      >{{ LabelConstant.buttonLabelSave }}</vs-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Customizations -->
      <!-- Validation Rules -->
      <vs-tab label="Validation Rules">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">Custom Validation Rules</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table p-4">
                  <div class="flex justify-between align-center flex-wrap w-full">
                    <h5 class="mb-2">Validation Rules</h5>
                    <vs-button
                      title="Save"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      class="float-left"
                      @click="validationrulesPopup = true"
                      >Add New</vs-button
                    >
                  </div>
                  <!-- table -->
                  <vs-table v-model="multipleSelected" :data="validationRulesList" pagination :max-items="itemsPerPage">
                    <template slot="thead">
                      <vs-th></vs-th>
                      <vs-th>Rule Name</vs-th>
                      <vs-th>Field Type</vs-th>
                      <vs-th>Validation Type</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="90">
                          <div class="table-action">
                            <vs-button
                              title="Edit"
                              color="primary"
                              @click="getValidationRuleDetails(data[indextr].Validation_Rule_Id)"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-trash-2"
                              class="mr-0"
                              @click="deleteValidationRUleConfirmation(data[indextr].Validation_Rule_Id)"
                            ></vs-button>
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].Validation_Rule_Name">{{ data[indextr].Validation_Rule_Name }}</vs-td>
                        <vs-td :data="data[indextr].Field_Type">{{ data[indextr].Field_Type }}</vs-td>
                        <vs-td :data="data[indextr].Validation_Type">{{ data[indextr].Validation_Type }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Work Flow -->
      <!-- Lead Handling -->
      <vs-tab label="Lead Handling">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">Lead Handling Workflow</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table p-4">
                  <div class="flex btn-search w-full">
                    <h5 class="mb-2">CRM Export Filters</h5>
                  </div>
                  <!-- table -->
                  <div class="responsive-table">
                    <table pagination class="table w-full  comparision-table">
                      <thead>
                        <th>Enabled</th>
                        <th>Rule Name</th>
                        <th>Scope</th>
                        <th>Assigned To</th>
                        <th>Actions</th>
                      </thead>
                      <draggable v-model="lhwFilters" tag="tbody" @start="drag = true" >
                        <tr :data="tr" :key="indextr" v-for="(tr, indextr) in lhwFilters">
                          <td> <vs-checkbox v-model="lhwFilters[indextr].Enabled"> </vs-checkbox></td>
                          <td :data="lhwFilters[indextr].Filter_Name">{{ lhwFilters[indextr].Filter_Name }}</td>
                          <td :data="lhwFilters[indextr].Scope">{{ lhwFilters[indextr].Scope }}</td>
                          <td :data="lhwFilters[indextr].Assigned_To">{{ lhwFilters[indextr].Assigned_To }}</td>
                          <td>
                            <div class="table-action w-full ">
                            <feather-icon class="mr-1 mt-3 mb-1 float-right"  icon="MoveIcon" ></feather-icon>
                              <vs-button
                                title="Copy"
                                color="warning"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-copy"
                                class="mr-1 my-1 float-right"
                                @click="lhwGetCRMAvailableFields(tr,'copy')"
                              ></vs-button>
                              <vs-button
                                v-if="lhwFilters[indextr].Scope != 'System'"
                                title="Delete"
                                color="danger"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-minus"
                                class="mr-1 my-1 float-right"
                                @click="lhwDeleteFilterConfirmation(lhwFilters[indextr])"
                              ></vs-button>
                              <vs-button
                                title="Plus"
                                color="success"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-plus"
                                class="mr-1 my-1 float-right"
                                @click="lhwGetCRMAvailableFields(tr,'add')"
                              ></vs-button>
                              <vs-button
                                  @click="lhwGetCRMAvailableFields(tr,'edit')"
                                title="Edit"
                                color="primary"
                                type="filled"
                                icon-pack="feather"
                                icon="icon-edit"
                                class="mr-1 my-1 float-right"
                              ></vs-button>
                            </div>
                          </td>
                        </tr>
                      </draggable>
                    </table>
                  </div>
                  <div class="float-left my-3 w-full">
                    <vs-button title="Save" color="primary" type="filled" class="float-left px-6" @click="lhwSaveFilterSettings()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <!-- Task Schedules -->
      <!-- Task Scheduler -->
      <vs-tab label="Task Scheduler">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card>
            <div class="vx-row find-people">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-2">Activity Scheduler</h2>
                  <h6 class="mb-2">Integration Partner - Salesforce - Production</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
                    Duis vulputate commodo lectus, ac blandit elit tincidunt id.
                  </p>
                </div>
                <div class="common-table p-4">
                  <div class="flex justify-between align-center flex-wrap w-full" >
                    <h5 class="mb-2">Activity Scheduler</h5>
                    <vs-button title="Save" color="success" type="filled" icon-pack="feather" icon="icon-plus" class="float-left" @click="schedulePopup = true">Add New</vs-button>
                  </div>
                  <!-- table -->
                  <vs-table :data="listTaskSchedule" pagination :max-items="itemsPerPage" >
                    <template slot="thead">
                      <vs-th></vs-th>
                      <vs-th>Schedule Name</vs-th>
                      <vs-th>Activity</vs-th>
                      <vs-th>Start Time</vs-th>
                      <vs-th>Recurrence </vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="90">
                          <div class="table-action">
                            <vs-button
                              @click="editGetIntegrationScheduleData(tr.Id)"
                              title="Edit"
                              color="primary"                          
                              type="filled"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="mr-2 float-left">
                            </vs-button>
                            <vs-button
                              @click="validationdeletePopup = true,scheduleId =tr.Id"
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-trash-2"
                              class="mr-0">
                            </vs-button>
                          </div>
                        </vs-td>
                        <vs-td >{{ tr.Schedule_Name }}</vs-td>
                        <vs-td >{{ tr.Activity_Name }}</vs-td>
                        <vs-td>{{ tr.TimeHour+":"+tr.TimeMinute+" "+tr.AMPM }}</vs-td>
                        <vs-td >{{ tr.Recurrence_Pattern }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
    </vs-tabs>
    
    <!-- crm Add/edit popup  (Custom Export Rules)-->
    <vs-popup class="delete-popup" :active.sync="validationdeletePopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button title="Delete" color="danger" type="filled" class="mr-2" @click="activeTab == 9?deleteTaskSchedule():deleteValidationRules()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button color="grey" @click="validationdeletePopup = false,validationRuleId =0" type="border" class="ml-4 grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    
    <!-- Module change warning -->
     <vs-popup class="warning-popup" :active.sync="warningModuleChange">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4>Are you sure, want to change module ?</h4>
            <p>By changing the module it will reset the rules.</p>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button color="grey" @click="yesChangeModule()" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelYes }}</vs-button>
        <vs-button color="grey" @click="cancelModuleChange()" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    
    <!--delete Popup  -->
    <vs-popup class="delete-popup" :active.sync="deletePopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button title="Delete" color="danger" type="filled" class="mr-2" @click="activeTab == 1 ? deleteCRMFilter() : lhwDeleteCRMFilter()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button color="grey" @click="deletePopup = false,deleteFilterId =null" type="border" class="ml-4 grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>

    <!-- warning popup remove conversion list item -->
    <vs-popup class="warning-popup" :active.sync="conversionRemoveWarning">
    <template>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <div class="popup-icon">
            <feather-icon  icon="AlertTriangleIcon"></feather-icon>
          </div>
          <h4 >Please select value to remove from list. </h4>
        </div>
      </div>
    </template>
    <div class="text-center mt-6 mb-8">
      <vs-button
        color="grey"
        @click="conversionRemoveWarning = false"
        type="border"
        class="grey-btn mr-3"
        >{{ LabelConstant.buttonLabelOkay }}
      </vs-button>
    </div>
    </vs-popup>
   
    <!-- warning popup before opening 'Type Base Input Value Popup' -->
    <vs-popup class="warning-popup" :active.sync="editRulePopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon  icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4 >Please select the appropriate values. </h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button color="grey" @click="editRulePopup = false" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelOkay }}</vs-button>
      </div>
    </vs-popup>
    
    <!--singleinputPopup  -->
    <vs-popup class="" title="single input" :active.sync="singleinputPopup">
      <template>
        <form data-vv-scope="inputValue">
          <div class="vx-row find-people">
            <div class="vx-col mb-3 w-full ">
              <p class="w-full float-left" >{{ crmApiName?crmApiName.Crm_Api_Name:'' }} {{ OperatorType?OperatorType.Value:'' }}</p>
              <div class="vx-row align-center">
                <div class="vx-col mb-3 w-full flex ">
                  <!-- <input-tag
                    v-if="(listInputCriteriaBaseType =='text' || listInputCriteriaBaseType =='number') && listInputIsOperatorList == true"
                    v-model="ruleGroupInputValueMultiple"
                    name="ruleGroupInputValueMultiple"
                    class="w-full"
                  ></input-tag> -->
                  <!-- <span class="text-danger text-sm" v-if="(listInputCriteriaBaseType =='text' || listInputCriteriaBaseType =='number') && listInputIsOperatorList == true">{{ errors.first('inputValue.ruleGroupInputValue') }}</span> -->
                  <vs-input
                    v-if="(listInputCriteriaBaseType =='text' || listInputCriteriaBaseType =='Text' || listInputCriteriaBaseType =='number' || listInputCriteriaBaseType =='decimal') && (listInputIsOperatorList == false && listInputIsPickList == false)"
                    class="w-full"
                    name="ruleGroupInputValue"
                    v-model="ruleGroupInputValue"
                    v-validate="listInputCriteriaBaseType =='number'?'numeric|required':listInputCriteriaBaseType =='decimal|required'?'decimal':''"
                  />
                  <div class="w-full mr-2"  v-if="listInputCriteriaBaseType =='datetime'">
                    <vs-input
                      v-model="ruleGroupDateDays"
                      name="ruleGroupDateDays"
                      class="w-full "
                      v-validate="'required|numeric'"
                    />
                    <div class="w-full">
                      <span class="text-danger text-sm">{{ errors.first('inputValue.ruleGroupDateDays') }}</span>
                    </div>
                  </div>
                  <v-select
                    v-if="listInputIsPickList == true && listInputIsOperatorList == false"
                    v-model="ruleGroupInputValue"
                    label="Value"
                    :options="listInputPickListValues?listInputPickListValues:[]"
                    name="ruleGroupInputValue"
                    class="w-full"
                    v-validate="'required'"
                  />
                  <v-select
                    v-if="listInputIsPickList == true && listInputIsOperatorList == true"
                    v-model="ruleGroupInputValueMultiple"
                    label="Value"
                    :options="listInputPickListValues?listInputPickListValues:[]"
                    name="ruleGroupInputValueMultiple"
                    class="w-full multiple-select"
                    v-validate="'required'"
                    multiple
                  />
                  <div class="w-full flex-fill" v-if="listInputCriteriaBaseType =='datetime'|| listInputCriteriaBaseType =='boolean'">
                    <v-select
                      v-model="ruleGroupInputValue"
                      label="text"
                      class="w-full flex-fill "
                      :options="listInputCriteriaBaseType =='datetime'?dateTimeOption:booleanOption"
                      name="ruleGroupInputValue"
                      v-validate="'required'"
                    />
                    <div class="w-full">
                      <span class="text-danger text-sm">{{ errors.first('inputValue.ruleGroupInputValue') }}</span> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row find-people" v-if="listInputIsOperatorList && !listInputIsPickList">
            <div class="vx-col  mb-3 w-full ">
              <div class="flex justify-between flex-wrap items-center">
                <div >
                  <!-- <span class="vs-input--label pr-2 ">{{ crmApiName?crmApiName.Crm_Api_Name:'' }} {{ OperatorType?OperatorType.Value:'' }}</span> -->
                  <div  class="w-full mr-2">
                    <vs-input               
                      name="ruleGroupInputValue"
                      v-model="ruleGroupInputValue"
                      v-validate="listInputCriteriaBaseType =='number'?'numeric':listInputCriteriaBaseType =='decimal'?'decimal':''"
                      class="w-full "
                    />     
                    <span class="text-danger text-sm">{{ errors.first('inputValue.ruleGroupInputValue') }}</span>             
                  </div>
                </div>
                <div>
                  <div class=" justify-center  w-full  mt-5 ">
                    <vs-button title="Plus" color="success" type="filled" icon-pack="feather" icon="icon-arrow-right" class="mr-2 mb-2 " @click="addValueToRule()"></vs-button>
                    <vs-button title="Delete" color="danger" type="filled" icon-pack="feather" icon="icon-arrow-left" class="mr-0 px-2" @click="removeRuleSelectedValue()"></vs-button>
                  </div>
                </div>
                <div class="mutiselect-custom sm:mt-0 mt-5">
                  <b-form-select v-model="ruleSeleted" :options="ruleGroupInputValueMultiple" :select-size="4" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button @click="activeTab == 1 ? saveCRMValue() : saveTriggerFilterActionValue()" color="primary" type="filled" class="">{{ LabelConstant.buttonLabelSave }}</vs-button>
        <vs-button color="grey" @click="cancelCRMValue()" type="border" class="ml-4 grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>

    <vs-popup class="campaign-second popup-width" title="Custom Export Rules" :active.sync="customrulesPopup">
      <template>
        <div class="vx-row">
          <!-- Keywords -->
          <div class="vx-col w-full mb-2">
            <p class="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Null aquam velit, vulputate eu pharetra nec, mattis ac neque.
            </p>
          </div>
        </div>
        <form data-vv-scope="filterDetails" class="from-to-camp">
          <div class="vx-row">
            <div class="vx-col flex items-top mb-6 w-full">
              <h5 class="pr-4 float-left md:pt-3 w-auto">Rule Name:</h5>
              <div class="w-2/3">
              <vs-input class="w-full" placeholder="Account Sync Query using industry and modified date" name="filterName" v-model="filterName" v-validate="'required'" />
                <span class="text-danger text-sm">{{ errors.first('filterDetails.filterName') }}</span>
              </div>
            </div>
            <div class="vx-col mb-6 w-full">
              <vs-checkbox class="ml-0" v-model="isFilterEnable">Enable Filter </vs-checkbox>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col flex flex-wrap items-center mb-4 w-full">
              <div class="flex-fill">
                <p class="pr-4 float-left">Tag records pullled from you CRM using this rule as :</p>
              </div>
              <div class="width-200">
                <v-select label="Value" :options="recordTagList" :reduce="(option) => option.Key" v-model="recordTagId" />
              </div>
            </div>   
          </div>
          <div class="vx-row">
            <div class="vx-col flex flex-wrap items-center mb-4 w-full">
              <div class="flex-fill">
                <p class="pr-4 float-left">Pull records from CRM using the following module:</p>
              </div>
              <div class="width-200">
                <v-select label="Value" :options="moduleList" :reduce="(option) => option.Key" v-model="moduleId" @input="changeModule()"/>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <!-- Keywords -->
            <!-- <div class="vx-col flex flex-wrap justify-start mb-2 w-full export-crm-group">
              <p class="pr-4 md:pt-3 my-2 float-left w-auto">If</p>
              <div class="md:w-1/3 pr-4 w-full my-2">
                <v-select v-model="crmParentFilterCoditionMet" :clearable="false" v-validate="'required'" name="crmParentFilterCoditionMet" placeholder="Any" label="Value" :options="conditionsMetList" />
                <span class="text-danger text-sm">{{ errors.first('filterDetails.crmParentFilterCoditionMet') }}</span>
              </div>
              <p class="pr-4 float-left md:pt-3 w-auto my-2">of the following conditions are met</p>
              <vs-button title="Add Group" color="success" type="filled" icon-pack="feather" icon="icon-plus" class="ml-auto my-2 self-start" @click="addChildGroup()">Add Group</vs-button>
            </div> -->
            <div class="vx-col w-full mb-8">
              <div class="send-camp">
                <div class="vx-row" v-if="crmLogicFilter">
                  <!-- <div class="row w-full mx-2" v-for="(tr, indextr) in crmLogicFilter.Child_Logic_Group" :key="indextr"> -->
                  <div class="vx-col w-full find-people">
                    <div class="filter-campaign">
                      <div class="flex flex-wrap justify-start mb-6 w-full">
                        <p class="pr-4 float-left  md:pt-3 w-auto my-2">If</p>
                          <div class="md:w-1/3 pr-4 w-full my-2">
                            <v-select :clearable="false" v-model="crmLogicFilter.Group_Condition_Met" label="Value" :options="conditionsMetList" />
                            <span class="text-danger text-sm mb-3 float-left w-full" v-if="errorType=='groupConditionMet'">Please select group condition</span>
                          </div>
                          <p class="pr-4 float-left  md:pt-3 w-auto my-2">of the following conditions are met</p>
                      </div>
                      <div class="flex flex-wrap items-top mb-1 w-full" v-for="(ruleGroup, index) in crmLogicFilter.Rule_Group" :key="index" >
                        <div class="md:w-1/5 pr-4 w-full my-2">
                          <v-select
                            label="Crm_Display_Name"
                            :options="moduleId ==1?accountCriteriaList:moduleId==2?contactCriteriaList:moduleId==3?leadCriteriaList:[]"
                            v-model="ruleGroup.Crm_Api_Name"
                            :clearable="false"
                            @input="changeCriteriaName(index)"
                          />
                          <span class="text-danger text-sm mb-3 float-left w-full" v-if="errorRuleGroupIndex ==index && errorType=='criteriaName'">Please select criteria name</span>
                        </div>
                        <div class="md:w-1/5 pr-4 w-full my-2">
                          <v-select v-model="ruleGroup.Operator_Id" label="Value" :options="ruleGroup.Crm_Api_Name?ruleGroup.Crm_Api_Name.Operator_Type:[]"/>
                          <span class="text-danger text-sm mb-3 float-left w-full" v-if="errorRuleGroupIndex ==index && errorType=='operatorId'">Please select operator type</span>
                        </div>
                        <div class="md:w-2/5 pr-4 w-full my-2">
                          <vs-input class="w-auto" v-model="ruleGroup.Values" :disabled="true" />
                          <span class="text-danger text-sm mb-3 float-left w-full" v-if="errorRuleGroupIndex ==index && errorType=='ruleValue'">Please enter value</span>
                        </div>
                        <div class="table-action justify-center md:w-1/5 w-full flex my-2">
                          <vs-button
                            color="warning"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-menu"
                            @click="getcrmValue(ruleGroup.Crm_Api_Name,ruleGroup.Operator_Id,index)"
                            class="mr-2 float-left"
                          ></vs-button>
                          <vs-button
                            @click="addRuleGroup(index)"
                            title="Plus"
                            color="success"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-plus"
                            class="mr-2 float-left"
                          ></vs-button>
                          <vs-button
                            @click="deleteRuleGroup(index)"
                            title="Delete"
                            color="danger"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-minus"
                            class="mr-0"
                          ></vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="" @click="saveSettingCRMFilter()">{{ LabelConstant.buttonLabelSave }}</vs-button>
        <vs-button color="grey" @click="cancelAction()" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    
    <!--validation rules Popup  -->
    <vs-popup
      class="campaign-second popup-width"
      title="Custom Validation Rule - Details"
      :active.sync="validationrulesPopup"
    >
      <template>
        <div class="common-table mb-6 p-4">
          <div class="flex w-full">
            <h5 class="mb-6">Add New Validation Rule</h5>
          </div>
          <!-- form 1 -->
          <form data-vv-scope="validationRuleForm" class="px-3">
            <div class="vx-row">
              <div class="vx-col flex flex-wrap items-center w-full">
                <span class="vs-input--label pr-2 float-left"
                  >Validation Rule Name:
                </span>
                <div class="md:w-1/4 pr-2 w-full">
                  <vs-input
                    class="w-full"
                    v-model="validationRuleName"
                    name="validationRuleName"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm">{{ errors.first('validationRuleForm.validationRuleName') }}</span>
                </div>
              </div>
              <div class="vx-col flex items-center mb-2 w-full">
                <span class="vs-input--label pr-4 float-left"
                  >Applied Field Type:
                </span>
                <div class="md:w-1/3 pr-4 w-full my-2">
                    <v-select
                    v-model="validationFieldType"
                    label="Value"
                    :options="validationFieldTypeList"
                  />
                </div>
              </div>
              <div class="vx-col flex items-center mb-2 w-full">
                <span class="vs-input--label pr-4 float-left"
                  >Validation Type:
                </span>
                <div class="md:w-1/3 pr-4 w-full my-2">
                  <v-select
                    v-model="validationType"
                    label="Value"
                    :reduce="options => options.Key" 
                    :options="validationFieldType?validationFieldType.Validation_Type_List:[]"
                  />
                </div>
              </div>
              <div class="vx-col flex items-center mb-2 w-full">
                <div>
                  <span class="vs-input--label pr-4 w-full float-left"
                    >Conversion List:
                  </span>
                  <div class="table-action mt-2 flex w-full justify-center">
                    <feather-icon
                      @click="conversionPopup = true"
                      icon="PlusCircleIcon"
                      class="text-success cursor-poiter mr-2"
                    ></feather-icon>
                    <feather-icon
                      @click="removeItemFromCoversionList()"
                      icon="MinusCircleIcon"
                      class="text-danger cursor-poiter"
                    ></feather-icon>
                  </div>
                </div>
                <div class="md:w-1/3 pr-4 w-full my-2">
                  <b-form-select
                    v-model="conversionListSelected"
                    :options="validationConversionList"
                    :select-size="6"
                  />
                </div>
              </div>
              <div class="text-center mt-6 mb-8">
                <vs-button title="No" color="warning" type="filled" class="mr-2"
                  >Test</vs-button
                >
                <vs-button
                  title="Yes"
                  color="primary"
                  type="filled"
                  class="mr-2"
                  @click="saveSettingValidationRule()"
                  >{{ LabelConstant.buttonLabelSave }}</vs-button
                >
                <vs-button
                  color="grey"
                  @click="cancelValidationRule()"
                  type="border"
                  class="grey-btn mr-3"
                  >{{ LabelConstant.buttonLabelCancel }}</vs-button
                >
              </div>
            </div>
          </form>
        </div>

        <!-- Conversion List: -->
        <vs-popup
          class="campaign-second popup-width"
          title="Add Conversion List"
          :active.sync="conversionPopup"
        >
          <template>
            <div class="common-table mb-6 p-4">
              <!-- form 1 -->
              <form data-vv-scope="conversionText" class="px-3">
                <div class="vx-row">
                  <div class="flex w-full justify-start items-center mt-2 px-3">
                    <div class="my-1">
                      <span class="vs-input--label pr-2 float-left"
                        >Convert Text Matching:
                      </span>
                    </div>
                    <div class="md:w-1/4 pr-2 w-full">
                      <vs-input
                        class="w-full"
                        v-model="fullWord"
                        name="fullWord"
                        v-validate="'required'"
                      />
                        <span class="text-danger text-sm">{{ errors.first('conversionText.fullWord') }}</span>
                    </div>
                    <span class="vs-input--label pr-2 float-left">To </span>
                    <div class="md:w-1/4 pr-2 w-full">
                      <vs-input
                        class="w-full"
                        v-model="shortWord"
                        name="shortWord"
                        v-validate="'required'"

                      />
                      <span class="text-danger text-sm">{{ errors.first('conversionText.shortWord') }}</span>
                    </div>
                  </div>

                  <div class="text-center mt-6 mb-8">
                    <vs-button
                      title="Yes"
                      color="primary"
                      type="filled"
                      class="mr-2"
                      @click="addItemToConversionList()"
                      >{{ LabelConstant.buttonLabelSave }}</vs-button
                    >
                    <vs-button
                      color="grey"
                      @click="cancelAddItem()"
                      type="border"
                      class="grey-btn mr-3"
                      >{{ LabelConstant.buttonLabelCancel }}</vs-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </template>
        </vs-popup>
      </template>
    </vs-popup>
    
    <!-- Lead Handling Workflow - Detail-->
    <vs-popup class="campaign-second popup-width" title="Lead Handling Workflow - Detail" :active.sync="workflowPopup">
      <template>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.</p>
          </div>
        </div>
        <div class="vx-col  mt-4 w-full">
            <vs-checkbox class="ml-0" v-model="lhwEnable">Enable</vs-checkbox>
        </div>
        <form data-vv-scope="lhwDetails" class="from-to-camp">
          <!-- step-1 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="1" />
                </div>
                <div>
                  <p>Set the Rule Name</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Give your rule a meaningful name that allows you to quickly know the purpose of the workflow.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col flex items-top mb-6 w-full">
                <h6 class="pr-4 pt-3 float-left w-auto">Rule Name:</h6>
                <div  class="w-2/3">
                  <vs-input class="w-full" v-model="lhwFilterName" name="lhwFilterName" v-validate="'required'"/>
                  <span class="text-danger text-sm">{{ errors.first('lhwDetails.lhwFilterName') }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- step-2 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="2"/>
                </div>
                <div>
                  <p>Choose Record Type to use for this rule</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>This rule will be applied to the following.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col  mb-6 w-full">
                <div class="flex items-center w-full">
                <vs-radio 
                  class="mr-2 mb-2" 
                  @change="populateTriggerFilterActionOptions()" 
                  name="lwhRecordTypeModule"
                  v-validate="'required'" 
                  v-model="lwhRecordTypeModule" 
                  :vs-value="recordTypeModule" 
                  v-for="(recordTypeModule, index) in lhwRecordTypeModuleList" 
                  :key="index"
                >
                  {{recordTypeModule.Option_Description}}
                </vs-radio>
                </div>
                <span class="text-danger text-sm">{{ errors.first('lhwDetails.lwhRecordTypeModule') }}</span>
              </div>
            </div>
          </div>
          <!-- step-3 -->
          <div class="common-table p-4 mb-6 shadow" v-if="filterLogicTrigger">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="3" />
                </div>
                <div>
                  <p>Setup Triggers</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Filter by VisualVisitor Application and settings.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col w-full">
                <h5>Triggers</h5>
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign mt-0 mb-3">
                        <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                          <p class="pr-4 my-2 float-left w-auto">If</p>
                          <div class="md:w-1/3 pr-4 w-full my-2">
                            <v-select label="Value" :options="lhwLogicalOptions" :reduce="(option) => option.Key" v-model="filterLogicTrigger.Group_Condition_Met" :clearable="false"/>
                          </div>
                          <p class="pr-4 float-left w-auto my-2"> of the following conditions are met</p>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full" v-for="(rg, index) in filterLogicTrigger.Rule_Group" :key="index">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select label="Criteria_Name" :options="lhwAvailableTriggerFieldOptions" v-model="rg.Criteria_Id" :clearable="false"/>
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select label="Value" :options="rg.Criteria_Id ? rg.Criteria_Id.Operator_Type : []" v-model="rg.Operator_Id" :clearable="false"/>
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input class="w-auto" v-model="rg.Values" :disabled="true"/>
                          </div>
                          <div class="table-action justify-center md:w-1/5 w-full flex my-2">
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="lhwGetAvailableValues(rg.Criteria_Id,rg.Operator_Id,index,3)"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                              @click="addRule('trigger',index)"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                              @click="deleteRule('trigger',index)"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-4 -->
          <div class="common-table p-4 mb-6 shadow" v-if="filterLogicFilter">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="4" />
                </div>
                <div>
                  <p>Setup Record Filter</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Filters the trigger data using record field values.</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col w-full">
                <h5>Record Filters</h5>
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign mt-0 mb-3">
                        <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                          <p class="pr-4 my-2 float-left w-auto">If</p>
                          <div class="md:w-1/3 pr-4 w-full my-2">
                            <v-select label="Value" :options="lhwLogicalOptions" :reduce="(option) => option.Key" v-model="filterLogicFilter.Group_Condition_Met" :clearable="false"/>
                          </div>
                          <p class="pr-4 float-left w-auto my-2"> of the following conditions are met</p>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full" v-for="(rg, index) in filterLogicFilter.Rule_Group" :key="index">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select label="Criteria_Name" :options="lhwAvailableFilterFieldOptions" v-model="rg.Criteria_Id" :clearable="false"/>
                          </div>
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select label="Value" :options="rg.Criteria_Id ? rg.Criteria_Id.Operator_Type : []" v-model="rg.Operator_Id" :clearable="false"/>
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input class="w-auto" v-model="rg.Values" :disabled="true"/>
                          </div>
                          <div class="table-action justify-center md:w-1/5 w-full flex my-2">
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="lhwGetAvailableValues(rg.Criteria_Id,rg.Operator_Id,index,4)"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                              @click="addRule('filter',index)"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                              @click="deleteRule('filter',index)"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-5 -->
          <div class="common-table p-4 mb-6 shadow" v-if="filterLogicAction">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="5" />
                </div>
                <div>
                  <p>Setup Action</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>If conditions are met the execute the following actions</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col mb-1 w-full">
                <h5>Actions</h5>
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign mt-0 mb-3">
                        <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                          <p class="pr-4 my-2 float-left w-auto">If</p>
                          <div class="md:w-1/3 pr-4 w-full my-2">
                            <v-select label="Value" :options="lhwLogicalOptions" :reduce="(option) => option.Key" v-model="filterLogicAction.Group_Condition_Met" :clearable="false" />
                          </div>
                          <p class="pr-4 float-left w-auto my-2"> of the following conditions are met</p>
                        </div>
                        <div class="flex flex-wrap items-center mb-1 w-full" v-for="(ag, index) in filterLogicAction.Action_Group" :key="index">
                          <div class="md:w-1/5 pr-4 w-full my-2">
                            <v-select label="Value" :options="lhwAvailableActionFieldOptions" v-model="ag.Action_Id" :clearable="false" />
                          </div>
                          <div class="md:w-2/5 pr-4 w-full my-2">
                            <vs-input class="w-auto" v-model="ag.Values" :disabled="true" />
                          </div>
                          <div class="table-action justify-center md:w-1/5 w-full flex my-2">
                            <vs-button
                              title="Copy"
                              color="warning"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-menu"
                              @click="lhwGetAvailableValues(ag.Action_Id,null,index,5)"
                              class="mr-2 float-left"
                            ></vs-button>
                            <vs-button
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-plus"
                              class="mr-2 float-left"
                              @click="addRule('action',index)"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-minus"
                              class="mr-0"
                              @click="deleteRule('action',index)"
                            ></vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- step-6 -->
          <div class="common-table p-4 mb-6 shadow">
            <div class="vx-row">
              <div class="vx-col flex items-center w-full">
                <div class="flex items-center">
                  <h5 class="">Step</h5>
                  <vs-avatar text="6" />
                </div>
                <div>
                  <p>Setup Scope</p>
                </div>
              </div>
              <div class="vx-col mb-6 w-full">
                <p>Define who can see and modify the rule</p>
                <vs-divider class="mt-0" />
              </div>
              <div class="vx-col mb-1 w-full">
                <h5>Scope</h5>
              </div>
              <div class="vx-col w-full mb-6">
                <div class="vx-row">
                  <div class="row w-full mx-2">
                    <div class="vx-col w-full find-people">
                      <div class="filter-campaign my-0">
                        <div class="flex flex-wrap items-center mb-1 w-full">
                          <div class="flex w-full flex-d-column justify-start mt-2 px-3">
                            <div class="w-full my-1">
                              <vs-radio v-model="lhwAdminOnly" :vs-value="'true'" name="lhwAdminOnly" v-validate="'required'">Admin Only</vs-radio>
                            </div>
                            <div class="w-full my-1">
                              <div class="flex flex-wrap items-top w-full">
                                <vs-radio v-model="lhwAdminOnly"  :vs-value="'false'" name="lhwAdminOnly" v-validate="'required'" class="mb-2 items-top pt-3">Assign to User</vs-radio>
                                <div class="md:w-1/3 ml-2 w-full mb-2">
                                  <v-select
                                    placeholder="Select"
                                    label="Value"
                                    :options="lhwScopeUserList"
                                    v-model="lhwAssignToObj"
                                    name="lhwAssignToObj"
                                    v-validate="lhwAdminOnly == 'false' ? 'required' : ''"
                                    class="width-150"
                                  />
                                  <span class="text-danger text-sm">{{ errors.first('lhwDetails.lhwAssignToObj') }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="w-full my-1">
                              <span class="text-danger text-sm">{{ errors.first('lhwDetails.lhwAdminOnly') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Update" color="primary" type="filled" class="mr-2" @click="lhwsaveSetting()">{{LabelConstant.buttonLabelSave}}</vs-button>
        <vs-button color="grey" @click="cancelAction()" type="border" class="ml-4 grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    
    <!-- Task Scheduler add/edit Popup  -->
    <vs-popup class="campaign-second popup-width" :title="scheduleId == 0?'Add Activity Schedule':'Edit Activity Schedule'" :active.sync="schedulePopup">
      <template>
        <div class="common-table mb-6 p-4">
          <!-- form 1 -->
          <form  class="px-3" data-vv-scope="scheduleIntegration"> 
            <div class="vx-row">
              <div class="vx-col flex items-top mb-6 w-full">
                <h6 class="pr-4 pt-3 float-left w-auto">Schedule Name:</h6>
                <div  class="w-2/3">
                  <vs-input class="w-full" name="scheduleName" v-model="scheduleName" v-validate="'required'"  /> 
                  <span class="text-danger text-sm">{{errors.first("scheduleIntegration.scheduleName")}}</span>           
                </div>
              </div>
              <div class="vx-col flex items-top mb-6 w-full">
                <h6 class="pr-4 pt-3 float-left w-auto">Start Time:</h6>
                <div  class="w-1/3">
                  <flat-pickr :config="configdateTimePicker" v-model="startTime" placeholder="Choose time" v-validate="'required'" name="startTime" />
                  <p class="text-danger  text-sm">{{errors.first("scheduleIntegration.startTime")}}</p>
                </div>
              </div>
              <div class="vx-col  items-top mb-6 w-full">
                <h6 class="pr-4 mb-4 w-100">Recurrence Pattern</h6>
                <div class="flex  flex-wrap w-full p-3">
                  <div class="md:w-auto w-full  ml-2  md:pr-6 pr-0">
                    <vs-radio class="mr-2 mb-2 flex"  vs-value="once" name="recurrencePattern" v-model="recurrencePattern">Once</vs-radio>
                    <vs-radio class="mr-2 flex mb-2" vs-value="weekly" name="recurrencePattern" v-model="recurrencePattern">Weekly</vs-radio>
                    <vs-radio class="mr-2 flex mb-2" vs-value="monthly" name="recurrencePattern" v-model="recurrencePattern">Monthly</vs-radio>
                  </div>
                  <div class="md:w-auto w-full  border-md-left md:pl-6 pl-0">
                      <!-- Once section -->
                      <div v-if="recurrencePattern == 'once'">
                        <p class="pr-4 my-2 float-left "> Activity will run only once</p>
                      </div>
                      <!-- end Once section -->
                      <!-- Weekly section -->
                    <div v-if="recurrencePattern == 'weekly'">
                      <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                        <p class="pr-4 my-2 float-left w-auto">Recur every week on:</p>
                         <!-- <div class="input-width pr-4  my-2">
                            <vs-input class="w-full" v-model="numberOfRecur" name="numberOfRecur" />
                          </div>
                          <p class="pr-4 float-left w-auto my-2">week(s) on:</p> -->
                      </div>
                      <div class="vx-col  mb-1 w-full export-crm-group">
                        <div class="flex flex-wrap items-center ">
                          <vs-checkbox class="py-2" vs-value="Sunday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Sunday </vs-checkbox>
                          <vs-checkbox class="py-2" vs-value="Monday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'" >Monday </vs-checkbox>
                          <vs-checkbox class="py-2" vs-value="Tuesday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Tuesday </vs-checkbox>
                          <vs-checkbox class="py-2" vs-value="Wednesday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Wednesday </vs-checkbox>
                        </div>
                        <div class="flex flex-wrap items-center">
                          <vs-checkbox class="py-2" vs-value="Thursday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Thursday </vs-checkbox>
                          <vs-checkbox class="py-2" vs-value="Friday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Friday </vs-checkbox>
                          <vs-checkbox class="py-2" vs-value="Saturday" v-model="recurDaysOfWeek" name="recurDaysOfWeek" v-validate="'required'">Saturday </vs-checkbox>
                        </div>
                      </div>
                      <span class="text-danger text-sm">{{errors.first("scheduleIntegration.recurDaysOfWeek")}}</span>
                    </div>
                    <!-- end Weekly section -->
                    <!-- Monthly section -->
                    <div v-if="recurrencePattern =='monthly'">
                      <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                        <!-- <vs-radio class="mr-2 flex" vs-value="day" name="monthType" v-model="monthType">Day</vs-radio> -->
                        <p class="mr-2 flex">Day</p>
                        <div class="input-width pr-4  my-2">
                          <vs-input class="w-full" name="recurDayOfMonth" v-model="recurDayOfMonth" v-validate="'required|numeric'" />
                        </div>
                        <p class="pr-4 my-2 float-left w-auto">of every</p>
                          <!-- <div class="input-width pr-4  my-2">
                            <vs-input class="w-full" />
                          </div> -->
                        <p class="pr-4 float-left w-auto my-2">1 months</p>
                      </div>
                      <span class="text-danger text-sm">{{errors.first("scheduleIntegration.recurDayOfMonth")}}</span>
                      <!-- <div class="vx-col flex flex-wrap items-center mb-1 w-full export-crm-group">
                           <vs-radio class="mr-2 flex" vs-value="custom" name="monthType" v-model="monthType">The</vs-radio>
                            <div class="select-width pr-4  my-2">
                                <v-select label="label" :clearable="false" :options="weekNumbers" v-model="weekNumber" />
                            </div>
                            <div class="select-width pr-4  my-2">
                                <v-select label="label" :clearable="false" :options="weekDays" v-model="weekDay" />
                            </div>
                            <p class="pr-4 my-2 float-left w-auto">of every</p>
                            <div class="input-width pr-4  my-2">
                              <vs-input class="w-full" name="" />
                            </div>
                            <p class="pr-4 float-left w-auto my-2">months</p>
                        </div> -->
                    </div>
                    <!-- end Monthly section -->  
                  </div>
                </div>
              </div>
              <div class="vx-col  items-top mb-6 w-full">
                <h6 class="pr-4 mb-3 w-100">What Activity Job to use</h6>
                <div class="flex  flex-wrap w-full p-3">
                  <div class="md:w-auto w-full  ml-2  md:pr-6 pr-0">
                        <vs-radio class="mr-2 mb-2 flex"   v-for="(activityList,index) in scheduleJobActivityList" :key="index" :vs-value="activityList.ActivityId" 
                          vs-name="scheduleActivity" v-validate="'required'" v-model="scheduleActivity" name="scheduleActivity">
                          {{ activityList.Activity_Name }}
                        </vs-radio>  
                    <span class="text-danger text-sm">{{errors.first("scheduleIntegration.scheduleActivity")}}</span>              
                  </div>    
                </div>
              </div>
              <div class="text-center ml-auto mt-6 mb-8">
                <vs-button title="Yes" color="primary" type="filled" class="mr-2"  @click="addEditIntegrationSchedule()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                <vs-button color="grey" @click="scheduleCancel()" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
              </div>
            </div>
          </form>
        </div>

        <!-- Conversion List: -->
        <vs-popup class="campaign-second popup-width" title="Add Conversion List" :active.sync="conversionPopup">
          <template>
            <div class="common-table mb-6 p-4">
              <!-- form 1 -->
              <form data-vv-scope="conversionText" class="px-3">
                <div class="vx-row">
                  <div class="flex w-full justify-start items-center mt-2 px-3">
                    <div class="my-1">
                      <span class="vs-input--label pr-2 float-left">Convert Text Matching:</span>
                    </div>
                    <div class="md:w-1/4 pr-2 w-full">
                      <vs-input class="w-full" v-model="fullWord" name="fullWord" v-validate="'required'"/>
                      <span class="text-danger text-sm">{{ errors.first('conversionText.fullWord') }}</span>
                    </div>
                    <span class="vs-input--label pr-2 float-left">To </span>
                    <div class="md:w-1/4 pr-2 w-full">
                      <vs-input class="w-full" v-model="shortWord" name="shortWord" v-validate="'required'"/>
                      <span class="text-danger text-sm">{{ errors.first('conversionText.shortWord') }}</span>
                    </div>
                  </div>
                  <div class="text-center mt-6 mb-8">
                    <vs-button title="Yes" color="primary" type="filled" class="mr-2" @click="addItemToConversionList()">{{ LabelConstant.buttonLabelSave }}</vs-button>
                    <vs-button color="grey" @click="cancelAddItem()" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </vs-popup>
      </template>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BFormSelect } from "bootstrap-vue";
import draggable from "vuedraggable";
import InputTag from "vue-input-tag";
import DateRangePicker from "../../components/filter/DateRangePicker";
import { Validator } from "vee-validate";
import VxCard from '../../components/vx-card/VxCard.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from "moment";

const dict = {
  custom: {
    filterName: {
      required: "Please enter filter name",
    },
    crmParentFilterCoditionMet:{
      required: "Please select conditions met",
    },
    ruleGroupInputValue:{
      numeric:"Please enter numeric values",
      decimal:"Please enter decimal values",
      required:"This field is required",
    },
    ruleGroupDateDays:{
      numeric:"Please enter numeric values",
      required:"This field is required",
    },
    webVisitorOptionVisitThreshold:{
      numeric:"Please enter numeric values",
    },
    webVisitorOptionVisitPeriod:{
      numeric:"Please enter numeric values",
    },
    webVisitorNoteOptionVisitThreshold:{
      numeric:"Please enter numeric values",
    },
    webVisitorNoteOptionVisitPeriod:{
      numeric:"Please enter numeric values",
    },
    shoppingOptionScorePeriod:{
      numeric:"Please enter numeric values",
    },
    shoppingOptionScoreThreshold:{
      numeric:"Please enter numeric values",
    },
    shoppingNoteOptionScoreThreshold:{
      numeric:"Please enter numeric values",
    },
    shoppingNoteOptionScorePeriod:{
      numeric:"Please enter numeric values",
    },
    validationRuleName:{
      required:"Please enter validation rule name"
    },
    shortWord:{
      required:"This field is required"
    },
    fullWord:{
      required:"This field is required"
    },
    lookingQuickExportOptionId:{
      required: "This field is required.",
    },
    lookingQuickExportClassificationId:{
      required: "This field is required.",
    },
    shoppingQuickExportClassificationId:{
      required: "This field is required.",
    },
    shoppingQuickExportOptionId:{
      required: "This field is required.",
    },
    contactQuickExportOptionId:{
      required: "This field is required.",
    },
    contactQuickExportClassificationId:{
      required: "This field is required.",
    },
    lhwFilterName: {
      required: "Please enter filter name.",
    },
    lwhRecordTypeModule:{
      required: "Please select record type.",
    },
    lhwAssignToObj:{
      required: "Please select user.",
    },
    lhwAdminOnly:{
      required: "Please select scope.",
    },
    startTime:{
      required: "This field is required.",
    },
    scheduleName:{
      required: "This field is required.",
    },
    recurDaysOfWeek:{
      required: "This field is required.",
    },
    scheduleActivity:{
      required: "This field is required.",
    },
    recurDayOfMonth:{
      required: "This field is required.",
    },
  },
};
Validator.localize("en", dict);
export default {
  components: {
    "v-select": vSelect,
    DateRangePicker,
    BFormSelect,
    draggable,
    InputTag,
    VxCard,
    flatPickr
  },
  props:['integrationData'],
  data: () => ({
    lhwFilters:[],
    workflowPopup:false,
    lhwEnable: false,
    lhwFilterName: null,
    lhwFilterId:0,
    lhwRecordTypeModuleList: [],
    lwhRecordTypeModule: null,
    lhwLogicalOptions: [],
    lhwAvailableTriggerFieldList: [],
    lhwAvailableFilterFieldList: [],
    lhwAvailableActionFieldList: [],
    lhwAvailableTriggerFieldOptions: [],
    lhwAvailableFilterFieldOptions: [],
    lhwAvailableActionFieldOptions: [],
    filterLogicTrigger: null,
    filterLogicFilter: null,
    filterLogicAction: null,
    formStepNumber: null,
    lhwDisplayOrder: null,
    lhwActionType: null,
    lhwAdminOnly: 'true',
    lhwAssignToObj: null,
    lhwScopeUserList: [],

    activeTab:0,
    //General Settings
    generalSettingpageText:'',
    connectionName:null,
    limitDailyAPICalls:false,
    dailyAPICalls:0,
    limitMaxSyncRecords:false,
    maxSyncRecords:0,
    allowReadAccessFromIntegrationPartner:false,
    allowWriteAccessIntoIntegrationPartner:false,
    integrationPartnerName:null,
    connectionStatus:false,
    isEventListner: false,

    //CRM Data Filter
    customrulesPopup: false,
    deletePopup: false,
    actionType:null,
    crmFilterData:[],
    isFilterEnable:false,
    filterName:null,
    filterId:0,
    recordTagId:0,
    moduleId:0,
    warningModuleChange:false,
    oldModule:0,
    conditionsMetList:[],
    moduleList:[],
    recordTagList:[],
    accountCriteriaList:[],
    contactCriteriaList:[],
    leadCriteriaList:[],
    filterCriteriaList:[],
    deleteFilterId:null,
    crmLogicFilter:null,
    crmParentFilterCoditionMet:null,
    listInputCriteriaBaseType :null,
    listInputIsOperatorList:false,
    listInputIsPickList:false,
    listInputPickListValues:null,
    ruleGroupInputValue:null,
    ruleGroupDateDays:null,
    singleinputPopup: false,
    dateTimeOption:[
      {value:"days",text:"days"},
      {value:"weeks",text:"weeks"},
      {value:"months",text:"months"},
      {value:"years",text:"years"},
    ],
    booleanOption:[
      {value:"true",text:"true"},
      {value:"false",text:"false"},
    ],
    crmApiName :null,
    OperatorType : null,
    inputRuleGroupIndex :-1,
    ruleSeleted:null,
    ruleGroupInputValueMultiple:[],
    displayOrder:0,
    errorRuleGroupIndex:-1,
    errorType:null,
    editRulePopup:false,

    //Account Mapping
    accountMapCRMAvailableFields:[],
    accountMapVVFieldList:[],
    CRMModuleText:null,
    accountFieldMapping:[],
    accountMapUpdateBlankFields:false,
    errorArrayAccount:null,
    accountCrmSelected:[],
    keyFieldAccount:null,

    //Contact Mapping
    contactMapCRMAvailableFields:[],
    contactMapVVFieldList:[],
    contactFieldMapping:[],
    contactMapUpdateBlankFields:false,
    errorArrayContact:null,
    contactCrmSelected:[],
    keyFieldContact:null,

    //Lead Mapping
    leadMapCRMAvailableFields:[],
    leadMapVVFieldList:[],
    leadFieldMapping:[],
    leadMapUpdateBlankFields:false,
    errorArrayLead:null,
    leadUseContactMapping:false,
    leadCrmSelected:[],
    keyFieldLead:null,

    //Global Lead Handling
    accountText:null,
    contactText:null,
    leadText:null,
    userList:[],
    importingIntoSystemList:[],
    exportingIntoSystemList:[],
    handlingDuplicateList:[],
    doNotImportList:[],
    defaultAccountOwnerUserId:null,
    defaultContactOwnerUserId:null,
    defaultLeadOwnerUserId:null,
    importToCRMAccountOption:null,
    importToCRMContactOption:null,
    importToCRMLeadOption:null,
    importToCRMDuplicateHandlingOption:null,
    exportToCRMAccountOption:null,
    exportToCRMContactOption:null,
    exportToCRMLeadOption:null,
    doNotImport:[],
    importNotificationEmail : "",
    exportNotificationEmail:"",

    //Module Lead handling
    moduleCrmName:null,
    recordListOptions:[],
    lookingQuickExportOptionId:null,
    lookingQuickExportClassificationId:null,

    shoppingQuickExportClassificationId:null,
    shoppingQuickExportOptionId:null,

    contactQuickExportOptionId:null,
    contactQuickExportClassificationId:null,
    
    classificationsOptions:[],
    allowQuickExportLooking:false,
    formCaptureOptionId:null,
    formCaptureClassificationId:null,
    formCaptureInsertNote:false,
    webVisitorOptionEnabled:false,
    webVisitorOptionVisitThreshold:null,
    webVisitorOptionVisitPeriod:null,
    webVisitorOptionId:null,
    webVisitorClassificationId:null,
    webVisitorInsertNote:false,
    webVisitorNoteOptionVisitThreshold:null,
    webVisitorNoteOptionVisitPeriod:null,
    allowQuickExportShopping:false,
    shoppingOptionEnabled: true,
    shoppingOptionScoreThreshold: null,
    shoppingOptionScorePeriod: null,
    shoppingOptionId: null,
    shoppingClassificationId: null,
    shoppingInsertNote: false,
    shoppingNoteOptionScoreThreshold: null,
    shoppingNoteOptionScorePeriod: null,
    allowQuickExportContact: false,
    callTrackingInsertNote: false,

    //validation rules
    validationRulesList:[],
    validationdeletePopup:false,
    validationRuleId:0,
    validationRuleName:null,
    validationFieldTypeList:[],
    validationFieldType:null,
    validationType:null,
    validationConversionList:[],
    conversionListSelected:null,
    shortWord:null,
    fullWord:null,
    conversionRemoveWarning:false,
    conversionPopup: false,
    multipleSelected:[],
    itemsPerPage:10,
    validationrulesPopup: false,

    //Task scheduler
    schedulePopup:false,
    scheduleId:0,
    listTaskSchedule: [],
    recurrencePattern:"once",
    scheduleName:null,
    recurDaysOfWeek:[],
    numberOfRecur:null,
    scheduleJobActivityList:null,
    scheduleActivity:null,
    recurDayOfMonth:null,
    startTime:null,
    configdateTimePicker: {
      enableTime: true,
      noCalendar: true,       
    },  
  }),
  watch: {
    activeTab(val){
      if(val ==0){
        this.getPartnerSummary();
        this.getGeneralSettings();
        this.getConnectionStatus();
      } else if(val == 1){
        this.getCRMFilters();
      } else if(val == 2){
        this.getAccountMapSettings();
      } else if(val == 3){
        this.getContactMapSettings();
      } else if(val == 4){
        this.getLeadMapSettings();
      } else if(val == 5){
        this.getPageListGlobalHandling();
      } else if(val == 6){
        this.getListsModuleLeadHandling();
      } else if(val == 7){
        this.getValidationRules();
        this.getValidationRulesListData();
      } else if(val == 9){
        this.getTaskScheduler();
        this.getListOfScheduleJobActivities();
      }
    },
     moduleId(val){
      if(!this.oldModule){
        this.oldModule = val;
      }
    },
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
  },
  mounted() {
    if(!this.integrationData){
      history.back();
    } else {
      this.getPartnerSummary();
      this.getGeneralSettings();
      this.getConnectionStatus();
      this.lhwGetFilters();
    }

    var that = this;
    window.addEventListener("focus", checkCRMConnectionStatus);

    function checkCRMConnectionStatus() {
      if (that.isEventListner) {
        that.getConnectionStatus();
      }
      that.isEventListner = false;
    }

    var menuitem = document.getElementsByClassName("vs-sidebar-group ");
    for (var i = 0; i < menuitem.length; i++) {
      var arrow = menuitem[i].getElementsByClassName(
        "feather-icon select-none relative feather-grp-header-arrow"
      );
      var text = menuitem[i].getElementsByClassName(
        "truncate mr-3 select-none"
      )[0].innerText;
      var uls = menuitem[i].getElementsByTagName("ul");
      if (text == "Configuration") {
        menuitem[i].className += " vs-sidebar-group-open";
        arrow[0].className += " rotate90";
        uls[0].style.maxHeight = "none";

        for (var j = 0; j < uls.length; j++) {
          var lis = uls[j].getElementsByTagName("li");
          for (var k = 0; k < lis.length; k++) {
            var textInner = lis[k].innerText;
            var a = lis[k].getElementsByTagName("a");
            if (textInner == "Integration Partner") {
              a[0].className += " router-link-exact-active router-link-active";
            }
          }
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      let menuitem = [];
      menuitem = document.getElementsByClassName("vs-sidebar-group ");
      for (var i = 0; i < menuitem.length; i++) {
        if (menuitem[i].classList.contains("vs-sidebar-group-open")) {
          let text = menuitem[i].getElementsByClassName(
            "truncate mr-3 select-none"
          )[0].innerText;
          let uls = menuitem[i].getElementsByTagName("ul");
          let arrow = menuitem[i].getElementsByClassName(
            "feather-icon select-none relative feather-grp-header-arrow"
          );
          let det = to.path;
          det = det.match("/configuration");
          if (text == "Configuration" && !det) {
            uls[0].style.maxHeight = 0;
            arrow[0].classList.remove("rotate90");
            menuitem[i].classList.remove("vs-sidebar-group-open");

            for (var j = 0; j < uls.length; j++) {
              var lis = uls[j].getElementsByTagName("li");
              for (var k = 0; k < lis.length; k++) {
                var textInner = lis[k].innerText;
                var a = lis[k].getElementsByTagName("a");
                if (textInner == "Integration Partner") {
                  a[0].className = "";
                }
              }
            }
          }
        }
      }
    }, 300);
    next();
  },
  methods: {
    lhwGetFilters(){
      this.$vs.loading();
      this.axios.get("/ws/WorkFlowLeadHandling/GetFilters?connection_Id="+this.integrationData.Connection_ID).then(response => {
        let data = response.data;
        this.lhwFilters = data;
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async lhwSaveFilterSettings(){
      this.lhwFilters.forEach((element,index) => {
          element.Display_Order = index+1;
      });
      this.$vs.loading();
      let input = this.lhwFilters;
      await this.axios.post("/ws/WorkFlowLeadHandling/SaveFilters", input)
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
          this.getCRMFilters();
          this.$vs.loading.close();
        }).catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
      });
    },
    lhwDeleteFilterConfirmation(data){
      this.deletePopup = true;
      this.deleteFilterId = data.Filter_Id;
    },
    async lhwDeleteCRMFilter(){
      this.$vs.loading();
      await this.axios.get("/ws/WorkFlowLeadHandling/DeleteFilter?filter_Id="+this.deleteFilterId)
      .then(() => {
        this.$vs.notify({
          title: "Success",
          text: "Filter Deleted Successfully",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000
        });
        this.deletePopup = false;
        this.lhwGetFilters();
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async lhwGetCRMAvailableFields(filterdata,type){
      this.$vs.loading();
      await this.axios.get("/ws/WorkFlowLeadHandlingDetail/GetAvailableFields?connection_Id="+this.integrationData.Connection_ID)
      .then(response => {
        let data = response.data;
        this.lhwRecordTypeModuleList = data.Module_Id_List
        this.lhwScopeUserList = data.Scope_User_List
        this.lhwLogicalOptions = data.Group_Condition_Met_List
        this.lhwAvailableTriggerFieldList = data.Available_Trigger_Field_List
        this.lhwAvailableFilterFieldList = data.Available_Filter_Field_List
        this.lhwAvailableActionFieldList = data.Available_Action_Field_List
        this.lhwActionType = type

        if(type =='edit' || type == 'copy'){
          this.lhwGetFilterSettings(filterdata.Filter_Id);
          this.lhwDisplayOrder = filterdata.Display_Order;
        } else {
          this.filterLogicTrigger = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Criteria_Id: null,
              Operator_Id: null,
              Values: null
            }],
            Child_Logic_Group: null,
          };

          this.filterLogicFilter = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Criteria_Id: null,
              Operator_Id: null,
              Values: null
            }],
            Child_Logic_Group: null,
          };

          this.filterLogicAction = {
            Group_Condition_Met: "all",
            Action_Group: [{
              Action_Id:null,
              Values: null,
            }],
          };
          this.workflowPopup = true;
          this.lhwDisplayOrder = filterdata.Display_Order+1;
        }
        this.$vs.loading.close();
      })
      .catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async lhwGetFilterSettings(id){
      this.$vs.loading();
      await this.axios.get("/ws/WorkFlowLeadHandlingDetail/GetSettings?filter_Id="+id).then(response => {
        let data = response.data;
        this.lhwEnable = data.Filter_Enabled;
        this.lhwFilterName = data.Filter_Name;
        this.lhwFilterId = data.Filter_Id;
        this.filterLogicTrigger = data.Trigger_Filter;
        this.filterLogicFilter = data.CRM_Field_Filter;
        this.filterLogicAction = data.Action_Filter;
        this.lhwAdminOnly = data.AdminOnly ? 'true' : 'false'

        this.lhwRecordTypeModuleList.forEach(moduleSingle => {
          if(moduleSingle.Data_Filter_Module_Option_Id == data.DataFilter_Module_Option_Id && moduleSingle.Module_Id == data.Module_Id){
            this.lwhRecordTypeModule = moduleSingle;
          }
        });

        this.lhwScopeUserList.forEach(user => {
          if(user.Key == data.AssignToId){
            this.lhwAssignToObj = user;
          }
        });
        
        this.populateTriggerFilterActionOptions()          
        
        let criteriaListTrigger = this.lhwAvailableTriggerFieldOptions
        let criteriaListFilter = this.lhwAvailableFilterFieldOptions
        let criteriaListAction = this.lhwAvailableActionFieldOptions
        
        this.filterLogicTrigger.Rule_Group.forEach(ruleGroup => {
          ruleGroup.Values = ruleGroup.Values?ruleGroup.Values.join():null;  
          criteriaListTrigger.forEach(el => {
            if(ruleGroup.Criteria_Id == el.Criteria_Id){
              ruleGroup.Criteria_Id = el;
              el.Operator_Type.forEach(op => {
                if(ruleGroup.Operator_Id == op.Key){
                  ruleGroup.Operator_Id = op;
                }
              });
            }
          });
        });
        this.filterLogicFilter.Rule_Group.forEach(ruleGroup => {
          ruleGroup.Values = ruleGroup.Values?ruleGroup.Values.join():null;  
          criteriaListFilter.forEach(el => {
            if(ruleGroup.Criteria_Id == el.Criteria_Id){
              ruleGroup.Criteria_Id = el;
              el.Operator_Type.forEach(op => {
                if(ruleGroup.Operator_Id == op.Key){
                  ruleGroup.Operator_Id = op;
                }
              });
            }
          });
        });
        this.filterLogicAction.Action_Group.forEach(ruleGroup => {
          ruleGroup.Values = ruleGroup.Values?ruleGroup.Values.join():null;  
          criteriaListAction.forEach(el => {
            if(ruleGroup.Action_Id == el.Key){
              ruleGroup.Action_Id = el;
            }
          });
        });

        this.workflowPopup = true;
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    addRule(type,index){
      if(type == 'trigger'){
        this.filterLogicTrigger.Rule_Group.splice(index+1,0,{
          Criteria_Id: null,
          Operator_Id: null,
          Values: null
        })
      }else if(type == 'filter'){
        this.filterLogicFilter.Rule_Group.splice(index+1,0,{
          Criteria_Id: null,
          Operator_Id: null,
          Values: null
        })
      }else{
        this.filterLogicAction.Action_Group.splice(index+1,0,{
          Action_Id:null,
          Values: null,
        })
      }
    },
    deleteRule(type,index){
      if(type == 'trigger'){
        this.filterLogicTrigger.Rule_Group.splice(index,1)
      }else if(type == 'filter'){
        this.filterLogicFilter.Rule_Group.splice(index,1)
      }else{
        this.filterLogicAction.Action_Group.splice(index,1)
      }
    },
    populateTriggerFilterActionOptions(){
      for (let i = 0; i < this.lhwAvailableTriggerFieldList.length; i++) {
        if(this.lhwAvailableTriggerFieldList[i].Module_Id == this.lwhRecordTypeModule.Module_Id){
          this.lhwAvailableTriggerFieldOptions = this.lhwAvailableTriggerFieldList[i].Criteria_List
        }
      }
      for (let i = 0; i < this.lhwAvailableFilterFieldList.length; i++) {
        if(this.lhwAvailableFilterFieldList[i].Module_Id == this.lwhRecordTypeModule.Module_Id){
          this.lhwAvailableFilterFieldOptions = this.lhwAvailableFilterFieldList[i].Criteria_List
        }
      }
      for (let i = 0; i < this.lhwAvailableActionFieldList.length; i++) {
        if(this.lhwAvailableActionFieldList[i].Module_Id == this.lwhRecordTypeModule.Module_Id){
          this.lhwAvailableActionFieldOptions = this.lhwAvailableActionFieldList[i].Action
        }
      }
    },
    async lhwGetAvailableValues(criteria,operator,rgIndex,formStepNumber){
      if((formStepNumber == 3 || formStepNumber == 4) && criteria && operator){
        this.$vs.loading();
        let input = {
          Connection_Id: this.integrationData.Connection_ID,
          Criteria_Id: criteria.Criteria_Id,
          Module_Id: this.lwhRecordTypeModule.Module_Id,
          Operator_Id:operator.Key,
          Requested_From_Step: formStepNumber
        };
        await this.axios.post("/ws/WorkFlowLeadHandlingDetailsValue/GetAvailableValues", input).then((response) => {
          let data = response.data;
          this.listInputCriteriaBaseType = data.Criteria_Base_Type;
          this.listInputIsOperatorList = data.Is_Operator_List;
          this.listInputIsPickList = data.Is_Pick_List;
          this.listInputPickListValues = data.Pick_List_Values;
          this.crmApiName = {Crm_Api_Name:criteria.Criteria_Name};
          this.OperatorType = operator;
          this.inputRuleGroupIndex = rgIndex;
          this.singleinputPopup = true;
          this.formStepNumber = formStepNumber;
          this.$vs.loading.close();
        }).catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      } else if(formStepNumber == 5 && criteria){
        this.$vs.loading();
        let input = {
          Connection_Id: this.integrationData.Connection_ID,
          Criteria_Id: criteria.Key,
          Module_Id: this.lwhRecordTypeModule.Module_Id,
          Operator_Id: 0,
          Requested_From_Step: formStepNumber
        };
        await this.axios.post("/ws/WorkFlowLeadHandlingDetailsValue/GetAvailableValues", input).then((response) => {
          let data = response.data;
          this.listInputCriteriaBaseType = data.Criteria_Base_Type;
          this.listInputIsOperatorList = data.Is_Operator_List;
          this.listInputIsPickList = data.Is_Pick_List;
          this.listInputPickListValues = data.Pick_List_Values;
          this.crmApiName = {Crm_Api_Name:criteria.Value};
          this.inputRuleGroupIndex = rgIndex;
          this.singleinputPopup = true;
          this.formStepNumber = formStepNumber;
          this.$vs.loading.close();
        }).catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });  
      } else {
        this.editRulePopup = true;
      }
    },
    saveTriggerFilterActionValue(){
      this.$validator.validateAll("inputValue").then(async (result) => {
        if(result){   
          if(this.formStepNumber == 3){
            this.filterLogicTrigger.Rule_Group.forEach((el,index) => {
              if(index == this.inputRuleGroupIndex){
                if(this.listInputCriteriaBaseType == 'datetime'){
                  el.Values = this.ruleGroupDateDays+' '+this.ruleGroupInputValue.value; 
                } else if (this.listInputCriteriaBaseType == 'boolean'){
                  el.Values = this.ruleGroupInputValue.text;
                }else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                  let arr = [];
                  this.ruleGroupInputValueMultiple.forEach(element => {
                    arr.push(element.Key);
                  });
                  el.Values = arr.join();
                } else if(this.listInputIsOperatorList == true){
                  el.Values = this.ruleGroupInputValueMultiple.join();
                } else if(this.listInputIsPickList == true){
                  el.Values = this.ruleGroupInputValue.Value;
                } else {
                  el.Values = this.ruleGroupInputValue; 
                } 
              }
            });
          }else if(this.formStepNumber == 4){
            this.filterLogicFilter.Rule_Group.forEach((el,index) => {
              if(index == this.inputRuleGroupIndex){
                if(this.listInputCriteriaBaseType == 'datetime'){
                  el.Values = this.ruleGroupDateDays+' '+this.ruleGroupInputValue.value; 
                } else if (this.listInputCriteriaBaseType == 'boolean'){
                  el.Values = this.ruleGroupInputValue.text;
                }else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                  let arr = [];
                  this.ruleGroupInputValueMultiple.forEach(element => {
                    arr.push(element.Key);
                  });
                  el.Values = arr.join();
                } else if(this.listInputIsOperatorList == true){
                  el.Values = this.ruleGroupInputValueMultiple.join();
                } else if(this.listInputIsPickList == true){
                  el.Values = this.ruleGroupInputValue.Value;
                } else {
                  el.Values = this.ruleGroupInputValue; 
                } 
              }
            });
          }else{
            this.filterLogicAction.Action_Group.forEach((el,index) => {
              if(index == this.inputRuleGroupIndex){
                if(this.listInputCriteriaBaseType == 'datetime'){
                  el.Values = this.ruleGroupDateDays+' '+this.ruleGroupInputValue.value; 
                } else if (this.listInputCriteriaBaseType == 'boolean'){
                  el.Values = this.ruleGroupInputValue.text;
                }else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                  let arr = [];
                  this.ruleGroupInputValueMultiple.forEach(element => {
                    arr.push(element.Key);
                  });
                  el.Values = arr.join();
                } else if(this.listInputIsOperatorList == true){
                  el.Values = this.ruleGroupInputValueMultiple.join();
                } else if(this.listInputIsPickList == true){
                  el.Values = this.ruleGroupInputValue.Value;
                } else {
                  el.Values = this.ruleGroupInputValue; 
                } 
              }
            });
          }
        
          this.singleinputPopup = false;
          this.inputRuleGroupIndex = -1;
          this.listInputCriteriaBaseType = null;
          this.listInputIsOperatorList = false;
          this.listInputIsPickList = false;
          this.listInputPickListValues = null;
          this.crmApiName = null;
          this.OperatorType = null;
          this.ruleGroupInputValue = null;
          this.ruleGroupInputValueMultiple = [];
          this.ruleSeleted = null;
          this.ruleGroupDateDays = null;
          this.formStepNumber = null;
        }
      });
    },
    async lhwsaveSetting(){
      this.$validator.validateAll("lhwDetails").then(async (result) => {
        if (result) {
          let filterLogicTriggerFinal;
          let filterLogicFilterFinal;
          let filterLogicActionFinal;
          let ans = this.lhwCheckGroupValue();
          if(ans.Boolean){
            let ruleGroupTrigger =[];
            let ruleGroupFilter =[];
            let actionGroupAction =[];
            if(this.filterLogicTrigger){
              this.filterLogicTrigger.Rule_Group.forEach(el => {
                ruleGroupTrigger.push({
                  Criteria_Id:el.Criteria_Id.Criteria_Id,
                  Operator_Id:el.Operator_Id.Key,
                  Values:el.Values.split(','),
                });
              });
              filterLogicTriggerFinal = {
                Group_Condition_Met: this.filterLogicTrigger.Group_Condition_Met,
                Rule_Group: ruleGroupTrigger,
              };
            } else {
              filterLogicTriggerFinal = {
                Group_Condition_Met:this.filterLogicTrigger.Group_Condition_Met,
                Rule_Group:[],
              };
            } 
            if(this.filterLogicFilter){
              this.filterLogicFilter.Rule_Group.forEach(el => {
                ruleGroupFilter.push({
                  Criteria_Id:el.Criteria_Id.Criteria_Id,
                  Operator_Id:el.Operator_Id.Key,
                  Values:el.Values.split(','),
                });
              });
              filterLogicFilterFinal = {
                Group_Condition_Met: this.filterLogicFilter.Group_Condition_Met,
                Rule_Group: ruleGroupFilter,
              };
            } else {
              filterLogicFilterFinal = {
                Group_Condition_Met:this.filterLogicFilter.Group_Condition_Met,
                Rule_Group:[],
              };
            } 
            if(this.filterLogicAction){
              this.filterLogicAction.Action_Group.forEach(el => {
                actionGroupAction.push({
                  Action_Id:el.Action_Id.Key,
                  Values:el.Values.split(','),
                });
              });
              filterLogicActionFinal = {
                Group_Condition_Met: this.filterLogicAction.Group_Condition_Met,
                Action_Group: actionGroupAction,
              };
            } else {
              filterLogicActionFinal = {
                Group_Condition_Met:this.filterLogicAction.Group_Condition_Met,
                Action_Group:[],
              };
            } 
            let input ={
              Connection_Id:this.integrationData.Connection_ID,
              Filter_Id:this.lhwActionType=='edit'?this.lhwFilterId:0,
              Filter_Name:this.lhwFilterName,
              Filter_Enabled:this.lhwEnable,
              Module_Id: this.lwhRecordTypeModule.Module_Id,
              DataFilter_Module_Option_Id: this.lwhRecordTypeModule.Data_Filter_Module_Option_Id,
              Trigger_Filter: filterLogicTriggerFinal,
              CRM_Field_Filter: filterLogicFilterFinal,
              Action_Filter: filterLogicActionFinal,
              Display_Order: this.lhwDisplayOrder,
              AdminOnly: this.lhwAdminOnly == 'true' ? true : false,
              AssignToId: this.lhwAssignToObj ? this.lhwAssignToObj.Key : null,
              AssignToDisplay: this.lhwAssignToObj ? this.lhwAssignToObj.Value : null,
            }
            this.$vs.loading();
            await this.axios.post("/ws/WorkFlowLeadHandlingDetail/SaveSettings", input).then((response) => {
              this.$vs.notify({
                title: "Success",
                text: response.data.Message,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
              setTimeout(() => {
                this.errors.clear();
              }, 100);
              this.cancelAction();
              this.lhwGetFilters();
              this.$vs.loading.close();
            }).catch(e => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Erorr",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
            });
          } else {
            this.editRulePopup = true
          }
        }
      });
    },
    lhwCheckGroupValue(){
      if(this.filterLogicTrigger){
        for (let j = 0; j < this.filterLogicTrigger.Rule_Group.length; j++) {
          if(!this.filterLogicTrigger.Rule_Group[j].Criteria_Id || !this.filterLogicTrigger.Rule_Group[j].Operator_Id || !this.filterLogicTrigger.Rule_Group[j].Values){
            return {Boolean:false}
          }
        }
      }
      if(this.filterLogicFilter){
        for (let j = 0; j < this.filterLogicFilter.Rule_Group.length; j++) {
          if(!this.filterLogicFilter.Rule_Group[j].Criteria_Id || !this.filterLogicFilter.Rule_Group[j].Operator_Id || !this.filterLogicFilter.Rule_Group[j].Values){
            return {Boolean:false}
          }
        }
      }
      if(this.filterLogicAction){
        for (let j = 0; j < this.filterLogicAction.Action_Group.length; j++) {
          if(!this.filterLogicAction.Action_Group[j].Action_Id || !this.filterLogicAction.Action_Group[j].Values){
            return {Boolean:false}
          }
        }
      }
      return {Boolean:true}
    },
    async getGeneralSettings() {
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartnerGeneralSettings/GetSettings?connection_Id="+this.integrationData.Connection_ID)
      .then(response => {
        let data = response.data;
        this.connectionName = data.Connection_Name;
        this.dailyAPICalls = data.Daily_API_Calls;
        this.limitDailyAPICalls = data.Limit_Daily_API_Calls;
        this.maxSyncRecords = data.Max_Sync_Records;
        this.limitMaxSyncRecords = data.Limit_Max_Sync_Records;
        this.allowReadAccessFromIntegrationPartner = data.Allow_Read_Access_From_Integration_Partner;
        this.allowWriteAccessIntoIntegrationPartner = data.Allow_Write_Access_Into_Integration_Partner;
        this.integrationPartnerName = data.Integration_Partner_Name;
        this.$vs.loading.close();
      })
      .catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async getConnectionStatus(){
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartnerGeneralSettings/GetConnectionStatus?connection_Id="+this.integrationData.Connection_ID)
      .then(response => {
        let data = response.data;
        this.connectionStatus = data.IsConnected;
        this.$vs.loading.close();
      })
      .catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async getPartnerSummary(){
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartnerGeneralSettings/GetPartnerSummary")
      .then(response => {
        let data = response.data;
        this.generalSettingpageText = data[0].Page_Text;
        this.$vs.loading.close();
      })
      .catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async saveGeneralSettings() {
      this.$vs.loading();
      let input = {
        "Connection_Id": this.integrationData.Connection_ID,
        "Connection_Name": this.connectionName,
        "Integration_Partner_Name": this.integrationPartnerName,
        "Limit_Daily_API_Calls": this.limitDailyAPICalls,
        "Daily_API_Calls": parseInt(this.dailyAPICalls),
        "Limit_Max_Sync_Records": this.limitMaxSyncRecords,
        "Max_Sync_Records": parseInt(this.maxSyncRecords),
        "Allow_Read_Access_From_Integration_Partner": this.allowReadAccessFromIntegrationPartner,
        "Allow_Write_Access_Into_Integration_Partner": this.allowWriteAccessIntoIntegrationPartner
      };
      await this.axios.post("/ws/IntegrationPartnerGeneralSettings/SaveSettings", input)
        .then((response) => {
          this.$vs.notify({
              title: "Success",
              text: response.data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
          });
          setTimeout(() => {
              this.errors.clear();
          }, 100);
          this.getGeneralSettings();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
    },
    async getCRMFilters(){
        this.$vs.loading();
        await this.axios.get("ws/IntegrationPartnerCRMDataFilter/GetFilters?connection_Id="+this.integrationData.Connection_ID)
        .then(response => {
          let data = response.data;
          this.crmFilterData= data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getCRMAvailableFields(filterdata,type){
        this.$vs.loading();
        await this.axios.get("ws/IntegrationPartnerCRMDataFilterDetails/GetAvailableFields?connection_Id="+this.integrationData.Connection_ID)
        .then(response => {
          let data = response.data;
          this.moduleList = data.Module_List;
          this.recordTagList = data.Record_Tag_List;
          this.conditionsMetList = data.Conditions_Met_List;
          this.accountCriteriaList = data.Criteria_List_Account;
          this.contactCriteriaList = data.Criteria_List_Contact;
          this.leadCriteriaList = data.Criteria_List_Lead;
          // this.filterCriteriaList = data.Criteria_List;
          this.actionType = type;
          if(type =='edit' || type == 'copy'){
            this.getFilterSettings(filterdata.Filter_Id);
            this.displayOrder = filterdata.Display_Order;
          } else {
            this.crmLogicFilter = {
              Group_Condition_Met: {Key: "all", Value: "All"},
              Rule_Group: [{
                Crm_Api_Name:null,
                Crm_Display_Name:null,
                Operator_Id:null,
                Values:null,
              }],
            };
            this.customrulesPopup = true;
            this.displayOrder = filterdata.Display_Order+1;
          }
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getFilterSettings(id){
      this.$vs.loading();
        await this.axios.get("/ws/IntegrationPartnerCRMDataFilterDetails/GetSettings?filter_Id="+id)
        .then(response => {
          let data = response.data;
          this.filterName = data.Filter_Name;
          this.isFilterEnable = data.Filter_Enabled;
          this.filterId = data.Filter_Id;
          this.moduleId = data.Module_Id;
          this.recordTagId = data.Record_Tag_Id;
          this.customrulesPopup = true;
          this.crmLogicFilter = data.Filter_Logic;
          
          this.conditionsMetList.forEach(condition => {
            if(condition.Key == this.crmLogicFilter.Group_Condition_Met){
              this.crmLogicFilter.Group_Condition_Met = condition;
            }
          });
          let criteriaList = data.Module_Id == 1?this.accountCriteriaList:data.Module_Id == 2?this.contactCriteriaList:data.Module_Id == 3?this.leadCriteriaList:[];
          this.crmLogicFilter.Rule_Group.forEach(ruleGroup => {
            ruleGroup.Values = ruleGroup.Values?ruleGroup.Values.join():null;  
            criteriaList.forEach(el => {
              if(ruleGroup.Crm_Api_Name == el.Crm_Api_Name){
                ruleGroup.Crm_Api_Name = el;
                el.Operator_Type.forEach(op => {
                  if(ruleGroup.Operator_Id == op.Key){
                    ruleGroup.Operator_Id = op;
                  }
                });
              }
            });
          });
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async saveFilterSettings(){
          this.crmFilterData.forEach((element,index) => {
              element.Display_Order = index+1;
          });
          this.$vs.loading();
          let input = this.crmFilterData;
          await this.axios.post("/ws/IntegrationPartnerCRMDataFilter/SaveFilters", input)
            .then((response) => {
                this.$vs.notify({
                  title: "Success",
                  text: response.data.Message,
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check",
                  position: "top-right",
                  time: 4000
                });
                this.getCRMFilters();
                this.$vs.loading.close();
              }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                  title: "Erorr",
                  text: e.response.data.Errors[0].Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-check",
                  position: "top-right",
                  time: 4000
                });
            });
    },
    deleteFilterConfirmation(data){
        this.deletePopup = true;
        this.deleteFilterId = data.Filter_Id;
    },
    async deleteCRMFilter(){
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartnerCRMDataFilter/DeleteFilter?filter_Id="+this.deleteFilterId)
      .then(() => {
        this.$vs.notify({
          title: "Success",
          text: "Filter Deleted Successfully",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000
        });
        this.deletePopup = false;
        this.getCRMFilters();
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async getcrmValue(Crm_Api_Name,Operator,ruleGroupIndex){
      if(Crm_Api_Name && Operator){
        this.$vs.loading();
          let input = {
            "Connection_Id": this.integrationData.Connection_ID,
            "Crm_Api_Name": Crm_Api_Name.Crm_Api_Name,
            "Crm_Display_Name": Crm_Api_Name.Crm_Display_Name,
            "Module_Id":this.moduleId,
            "Operator_Id": Operator.Key
          };
          await this.axios.post("/ws/IntegrationPartnerCRMDataFilterDetailsValue/GetAvailableValues", input)
          .then((response) => {
            let data = response.data;
            this.listInputCriteriaBaseType = data.Criteria_Base_Type;
            this.listInputIsOperatorList = data.Is_Operator_List;
            this.listInputIsPickList = data.Is_Pick_List;
            this.listInputPickListValues = data.Pick_List_Values;
            this.crmApiName = Crm_Api_Name;
            this.OperatorType = Operator;
            this.inputRuleGroupIndex = ruleGroupIndex;
            this.singleinputPopup = true;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      } else {
        this.editRulePopup = true;
      }
    },
    addRuleGroup(ruleGroupIndex){
          this.crmLogicFilter.Rule_Group.splice(
        ruleGroupIndex + 1,
        0,
        {
            Crm_Api_Name:null,
            Crm_Display_Name:null,
            Operator_Id:null,
            Values:null,
        }
      );
    },
    deleteRuleGroup(ruleGroupIndex){
        this.crmLogicFilter.Rule_Group.splice(ruleGroupIndex, 1);
    },
    saveCRMValue(){
      this.$validator.validateAll("inputValue").then(async (result) => {
        if(result){         
          this.crmLogicFilter.Rule_Group.forEach((el,index) => {
            if(index == this.inputRuleGroupIndex){
              if(this.listInputCriteriaBaseType == 'datetime'){
                el.Values = this.ruleGroupDateDays+' '+this.ruleGroupInputValue.value; 
              } else if (this.listInputCriteriaBaseType == 'boolean'){
                el.Values = this.ruleGroupInputValue.text;
              }else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                let arr = [];
                this.ruleGroupInputValueMultiple.forEach(element => {
                  arr.push(element.Key);
                });
                el.Values = arr.join();
              } else if(this.listInputIsOperatorList == true){
                el.Values = this.ruleGroupInputValueMultiple.join();
              } else if(this.listInputIsPickList == true){
                el.Values = this.ruleGroupInputValue.Key;
              } else {
                el.Values = this.ruleGroupInputValue; 
              } 
            }
          });
          this.singleinputPopup = false;
          this.inputRuleGroupIndex = -1;
          this.listInputCriteriaBaseType = null;
          this.listInputIsOperatorList = false;
          this.listInputIsPickList = false;
          this.listInputPickListValues = null;
          this.crmApiName = null;
          this.OperatorType = null;
          this.ruleGroupInputValue = null;
          this.ruleGroupInputValueMultiple = [];
          this.ruleSeleted = null;
          this.ruleGroupDateDays = null;
        }
      });
    },
    cancelCRMValue(){
      this.singleinputPopup = false;
      setTimeout(() => {
        this.errors.clear();
      }, 100);
      this.inputRuleGroupIndex = -1;
      this.listInputCriteriaBaseType = null;
      this.listInputIsOperatorList = false;
      this.listInputIsPickList = false;
      this.listInputPickListValues = null;
      this.crmApiName = null;
      this.OperatorType = null;
      this.ruleGroupInputValue = null;
      this.ruleGroupInputValueMultiple = [];
      this.ruleSeleted = null;
      this.ruleGroupDateDays = null;
    },
    async saveSettingCRMFilter(){
      this.$validator.validateAll("filterDetails").then(async (result) => {
        if (result) {
          let filterLogicFinal;
          let ans = this.checkGroupValue();
          if(ans.Boolean){
            let ruleGroup =[];
            if(this.crmLogicFilter){
                this.crmLogicFilter.Rule_Group.forEach(el => {
                  ruleGroup.push({
                    Crm_Api_Name:
                      el
                        .Crm_Api_Name.Crm_Api_Name,
                    Crm_Display_Name: el
                        .Crm_Api_Name.Crm_Display_Name,
                    Operator_Id:
                      el.Operator_Id.Key,
                    Values:el.Values.split(','),
                  });
                });
                  filterLogicFinal = {
        Group_Condition_Met: this.crmLogicFilter.Group_Condition_Met.Key,
        Rule_Group: ruleGroup,
      };
            } else {
              filterLogicFinal = {
                Group_Condition_Met:this.crmLogicFilter.Group_Condition_Met.Key,
                Rule_Group:[],
              };
            } 
            let input ={
              Connection_Id:this.integrationData.Connection_ID,
              Filter_Id:this.actionType=='edit'?this.filterId:0,
              Filter_Name:this.filterName,
              Filter_Enabled:this.isFilterEnable,
              Display_Order:this.displayOrder,
              Filter_Logic:filterLogicFinal,
              "Module_Id": this.moduleId,
              "Record_Tag_Id": this.recordTagId,
            }
            await this.axios.post("ws/IntegrationPartnerCRMDataFilterDetails/SaveSettings", input)
            .then((response) => {
              this.$vs.notify({
                title: "Success",
                text: response.data.Message,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
              setTimeout(() => {
                this.errors.clear();
              }, 100);
              this.cancelAction();
              this.getCRMFilters();
              this.$vs.loading.close();
            })
            .catch(e => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Erorr",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
            });
          } else {
            this.editRulePopup = true
          }
        }
      });
    },
    checkGroupValue(){
      if(this.crmLogicFilter){
        for (let j = 0; j < this.crmLogicFilter.Rule_Group.length; j++) {
          if(!this.crmLogicFilter.Rule_Group[j].Crm_Api_Name || !this.crmLogicFilter.Rule_Group[j].Operator_Id || !this.crmLogicFilter.Rule_Group[j].Values){
            return {Boolean:false}
          }
        }
      }
      return {Boolean:true}
    },
    cancelAction(){
      this.customrulesPopup = false;
      this.moduleId = 0;
      this.recordTagId = 0;
      this.isFilterEnable=false;
      this.filterName=null;
      this.filterId=0;
      this.conditionsMetList=[];
      this.filterCriteriaList=[];
      this.deleteFilterId=null;
      this.crmLogicFilter=null;
      this.crmParentFilterCoditionMet=null;
      this.inputRuleGroupIndex = -1;
      this.listInputCriteriaBaseType = null;
      this.listInputIsOperatorList = false;
      this.listInputIsPickList = false;
      this.listInputPickListValues = null;
      this.crmApiName = null;
      this.OperatorType = null;
      this.ruleGroupInputValue = null;
      this.ruleGroupInputValueMultiple = [];
      this.ruleSeleted = null;
      this.ruleGroupDateDays = null;
      this.actionType = null;
      this.displayOrder = 0;
      this.errorRuleGroupIndex = -1;
      this.errorType=null;

      this.workflowPopup = false;
      this.lhwEnable = false;
      this.lhwFilterName = null;
      this.lhwFilterId = 0;
      this.lhwRecordTypeModuleList = [];
      this.lwhRecordTypeModule = null;
      this.lhwLogicalOptions = [];
      this.lhwAvailableTriggerFieldList = [];
      this.lhwAvailableFilterFieldList = [];
      this.lhwAvailableActionFieldList = [];
      this.lhwAvailableTriggerFieldOptions = [];
      this.lhwAvailableFilterFieldOptions = [];
      this.lhwAvailableActionFieldOptions = [];
      this.filterLogicTrigger = null;
      this.filterLogicFilter = null;
      this.filterLogicAction = null;
      this.formStepNumber = null;
      this.lhwDisplayOrder = null;
      this.lhwActionType = null;
      this.lhwAdminOnly = 'true';
      this.lhwAssignToObj = null;
      this.lhwScopeUserList = [];

      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    changeCriteriaName(ruleIndex){
      this.crmLogicFilter.Rule_Group.forEach((element,index) => {
            if(index == ruleIndex){
              element.Operator_Id = null;
              element.Values = null;
            }
      });
    },
    async getAccountMapSettings(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerAccountMap/GetSettings?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.accountFieldMapping = data.CurrentRowValues;
            this.accountMapUpdateBlankFields = data.Do_Not_Update_Blank;
            this.getAccountMapAvailableFields();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getAccountMapAvailableFields(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerAccountMap/GetAvailableFields?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            //this.accountMapCRMAvailableFields = data.CRM_Available_Fields;
            this.CRMModuleText = data.CRM_Module_Text;
            let tempCrmFields = data.CRM_Available_Fields;
            
            this.accountCrmSelected = [];
            tempCrmFields.forEach(element => {
                this.accountFieldMapping.forEach(crv => {
                    if (crv.Is_Update_Field) {
                    this.keyFieldAccount = crv.Field_API_Name;
                  }
                  if(element.Field_API_Name == crv.Field_API_Name){
                      element.CurrentRowValues = crv;
                        element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find( ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id );
                      this.accountCrmSelected.push(crv.VV_Field_Id.VV_Field_Id);
                  }
                });
              if(!element.CurrentRowValues){
                  element.CurrentRowValues ={
                    Default_Value: "",
                    Example: null,
                    Field_API_Name: element.Field_API_Name,
                    Overwrite: false,
                    VV_Field_Id: null,
                    Validation_Rule_Id: null,
                  };
              }
            });
            this.accountMapCRMAvailableFields = tempCrmFields;
            this.accountMapVVFieldList = data.VV_Field_List;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    checkAccountMapValues(){
      let crmField = this.accountMapCRMAvailableFields;
      let errorArray = {
        ValidationRule:[],
        vvField:[],
      };
      crmField.forEach((element,index) => {
        if(element.Required_Field && !element.CurrentRowValues.VV_Field_Id){
          errorArray.vvField.push(index);
        }
        // if(element.CurrentRowValues.VV_Field_Id && !element.CurrentRowValues.Default_Value){
        //   errorArray.DefaulValue.push(index);
        // }
        if(element.CurrentRowValues.VV_Field_Id && !element.CurrentRowValues.Validation_Rule_Id){
          errorArray.ValidationRule.push(index);
        }
      });
      this.errorArrayAccount = errorArray;
      if(errorArray.ValidationRule.length || errorArray.vvField.length ){
        return false;
      } else {
        return true;
      }
    },
    updateAccountFieldMapValues(index){
      let crmSelected = [];
      this.accountMapCRMAvailableFields.forEach(element => {
        if(element.CurrentRowValues.VV_Field_Id){
          crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
        }
      });
      this.accountCrmSelected = crmSelected;
      if(this.accountMapCRMAvailableFields[index].CurrentRowValues.VV_Field_Id){
        this.accountMapCRMAvailableFields[index].CurrentRowValues.Overwrite = true;
        if(this.accountMapCRMAvailableFields[index].Available_Validation_Rules.length == 1){
          this.accountMapCRMAvailableFields[
          index
        ].CurrentRowValues.Validation_Rule_Id =
          this.accountMapCRMAvailableFields[
            index
          ].Available_Validation_Rules[0].Key;
        }
      } else {
        this.accountMapCRMAvailableFields[index].CurrentRowValues.Validation_Rule_Id = '';
        this.accountMapCRMAvailableFields[index].CurrentRowValues.Overwrite = false;
      }
      this.accountMapCRMAvailableFields[index].CurrentRowValues.Default_Value = ''
    },
    async saveAccountMapSetting(){
      let res = this.checkAccountMapValues();
      let ans = false;
      if(this.keyFieldAccount && res){
        ans = true;
      } else {
        if (!this.keyFieldAccount) {
        ans = false;
          this.$vs.notify({
            title: "Erorr",
            text: "Please select one field as a id field",
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        }
      }
      
        if(res && ans){
          let temp = this.accountMapCRMAvailableFields;
          let crv = [];
          temp.forEach(element => {
            if(element.CurrentRowValues.VV_Field_Id){
              crv.push({
                "VV_Field_Id": element.CurrentRowValues.VV_Field_Id.VV_Field_Id,
                "Field_API_Name": element.Field_API_Name,
                "Field_Display_Name":element.Field_Display_Name,
                "Is_Key_Field":element.Required_Field,
                "Is_Update_Field":element.Field_API_Name == this.keyFieldAccount? true:false,
                "Validation_Rule_Id": element.CurrentRowValues.Validation_Rule_Id,
                "Default_Value": element.CurrentRowValues.Default_Value,
                "Overwrite": element.CurrentRowValues.Overwrite,
              });
            }
          });
          this.$vs.loading();
          let input = {
            CurrentRowValues:crv,
            Do_Not_Update_Blank:this.accountMapUpdateBlankFields,
            Connection_Id:this.integrationData.Connection_ID,
          };
          await this.axios.post("/ws/IntegrationPartnerAccountMap/SaveSettings", input)
          .then((response) => {
            let data = response.data;
            if(data.Transaction_Successful){  
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.getAccountMapSettings();
            window.scrollTo(0, 0);
            this.$vs.loading.close();
            } else {
                this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
              this.$vs.loading.close();
            }
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      }
    },
    async getContactMapSettings(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerContactMap/GetSettings?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.contactFieldMapping = data.CurrentRowValues;
            this.contactMapUpdateBlankFields = data.Do_Not_Update_Blank;
            this.getContactMapAvailableFields();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getContactMapAvailableFields(){
      this.$vs.loading();
        await this.axios.get("/ws/IntegrationPartnerContactMap/GetAvailableFields?connection_Id="+this.integrationData.Connection_ID)
        .then(response => {
          let data = response.data;
          
          this.CRMModuleText = data.CRM_Module_Text;
          let temp = data.CRM_Available_Fields;
          this.contactCrmSelected = [];
          temp.forEach(element => {
              this.contactFieldMapping.forEach(crv => {
                  if (crv.Is_Update_Field) {
            this.keyFieldContact = crv.Field_API_Name;
          }
              if (element.Field_API_Name == crv.Field_API_Name) {
                // contactSelected.push(crv.VV_Field_Id);
                element.CurrentRowValues = crv;
                element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find(
                  ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id
                );
                // this.contactCRMSelected.push(crv.VV_Field_Id.VV_Field_Id);
                this.contactCrmSelected.push(crv.VV_Field_Id.VV_Field_Id);
              }
              });
              if(!element.CurrentRowValues){
                  element.CurrentRowValues ={
                  Default_Value: "",
                  Example: null,
                  Field_API_Name: element.Field_API_Name,
                  Overwrite: false,
                  VV_Field_Id: null,
                  Validation_Rule_Id: null,
                };
            }
          });
            this.contactMapCRMAvailableFields = temp;
            this.contactMapVVFieldList = data.VV_Field_List;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    checkContactMapValues(){
      let crmField = this.contactMapCRMAvailableFields;
      let errorArray = {
        vvField: [],
        ValidationRule:[],
      };
      crmField.forEach((element,index) => {
        if (
      element.Required_Field &&
      !element.CurrentRowValues.VV_Field_Id
    ) {
      errorArray.vvField.push(index);
    }
        if(element.CurrentRowValues.VV_Field_Id && !element.CurrentRowValues.Validation_Rule_Id){
          errorArray.ValidationRule.push(index);
        }
      });
      this.errorArrayContact = errorArray;
      if(errorArray.vvField.length || errorArray.ValidationRule.length){
        return false;
      } else {
        return true;
      }
    },
    updateContactFieldMapValues(index){
      let crmSelected = [];
      this.contactMapCRMAvailableFields.forEach(element => {
        if(element.CurrentRowValues.VV_Field_Id){
          crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
        }
      });
      this.contactCrmSelected = crmSelected;
      if(this.contactMapCRMAvailableFields[index].CurrentRowValues.VV_Field_Id){
        this.contactMapCRMAvailableFields[index].CurrentRowValues.Overwrite = true;
        if(this.contactMapCRMAvailableFields[index].Available_Validation_Rules.length == 1){
          this.contactMapCRMAvailableFields[index].CurrentRowValues.Validation_Rule_Id =this.contactMapCRMAvailableFields[index].Available_Validation_Rules[0].Key;
        }
      } else {
        this.contactMapCRMAvailableFields[index].CurrentRowValues.Validation_Rule_Id = ''
        this.contactMapCRMAvailableFields[index].CurrentRowValues.Overwrite = false;
      }
        this.contactMapCRMAvailableFields[index].CurrentRowValues.Default_Value = ''
    },
    async saveContactMapSettings(){
        let res = this.checkContactMapValues();
            let ans = false;
          if(this.keyFieldContact && res){
            ans = true;
          } else {
            if (!this.keyFieldContact) {
            ans = false;
              this.$vs.notify({
                title: "Erorr",
                text: "Please select one field as a id field",
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
            }
          }
        if(res && ans ){
          let temp = this.contactMapCRMAvailableFields;
          let crv = [];
          temp.forEach(element => {
            if(element.CurrentRowValues.VV_Field_Id){
              crv.push({
                "VV_Field_Id": element.CurrentRowValues.VV_Field_Id.VV_Field_Id,
                "Field_API_Name": element.Field_API_Name,
                "Field_Display_Name":element.Field_Display_Name,
                "Is_Key_Field":element.Required_Field,
                "Is_Update_Field":element.Field_API_Name == this.keyFieldContact? true: false,
                "Validation_Rule_Id": element.CurrentRowValues.Validation_Rule_Id,
                "Default_Value": element.CurrentRowValues.Default_Value,
                "Overwrite": element.CurrentRowValues.Overwrite,
                "Example": element.Example
              });
            }
          });
          this.$vs.loading();
          let input = {
            CurrentRowValues:crv,
            Do_Not_Update_Blank:this.contactMapUpdateBlankFields,
            Connection_Id: this.integrationData.Connection_ID,
          };
          await this.axios.post("/ws/IntegrationPartnerContactMap/SaveSettings", input)
          .then((response) => {
            let data = response.data;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.getContactMapSettings();
            window.scrollTo(0, 0);
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      }
    },
    async getLeadMapSettings(){
      this.$vs.loading();
        await this.axios.get("/ws/IntegrationPartnerLeadMap/GetSettings?connection_Id="+this.integrationData.Connection_ID)
        .then(response => {
            let data = response.data;
            this.leadFieldMapping = data.CurrentRowValues;
            this.leadMapUpdateBlankFields = data.Do_Not_Update_Blank;
            this.getLeadMapAvailableFields();
            this.$vs.loading.close();
        })
        .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
        });
    },
    async getLeadMapAvailableFields(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerContactMap/GetAvailableFields?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.CRMModuleText = data.CRM_Module_Text;
            let temp = data.CRM_Available_Fields;
            temp.forEach(element => {
                this.leadFieldMapping.forEach(crv => {
                    if (crv.Is_Update_Field) {
                      this.keyFieldLead = crv.Field_API_Name;
                    }
                if (element.Field_API_Name == crv.Field_API_Name) {
                    element.CurrentRowValues = crv;
                    element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find(
                      ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id
                    );
                  this.leadCrmSelected.push(crv.VV_Field_Id.VV_Field_Id);
                }
                });
                if(!element.CurrentRowValues){
                    element.CurrentRowValues ={
                    Default_Value: "",
                    Example: null,
                    Field_API_Name: element.Field_API_Name,
                    Overwrite: false,
                    VV_Field_Id: '',
                    Validation_Rule_Id: null,
                  };
              }
            });
              this.leadMapVVFieldList = data.VV_Field_List;
              this.leadMapCRMAvailableFields = temp;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async copyMappingFromContact(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerLeadMap/GetContactSettings?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            let temp = this.leadMapVVFieldList;
            this.leadCrmSelected = [];
            temp.forEach(element => {
              data.CurrentRowValues.forEach(crv => {
                if(element.VV_Field_Id == crv.VV_Field_Id){
                  element.Field_API_Name = this.leadMapCRMAvailableFields.find( ({ Field_API_Name }) => Field_API_Name === crv.Field_API_Name );
                  element.CurrentRowValues = crv;
                }
                this.leadCrmSelected.push(crv.Field_API_Name);
              });
              if(!element.CurrentRowValues){
                    element.Field_API_Name = null;
                    element.CurrentRowValues ={
                    Default_Value: "",
                    Example: null,
                    Field_API_Name: '',
                    Overwrite: false,
                    VV_Field_Id: element.VV_Field_Id,
                    Validation_Rule_Id: null,
                  };
              }
            });
            this.leadMapVVFieldList = temp;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    checkLeadMapValues(){
          let crmFields = this.leadMapCRMAvailableFields;
          let errorArray = {
            vvField:[],
            ValidationRule:[],
        };
        crmFields.forEach((element,index) => {
          if(element.Required_Field && !element.CurrentRowValues.VV_Field_Id){
            errorArray.vvField.push(index);
          }
          if(element.CurrentRowValues.VV_Field_Id && !element.CurrentRowValues.Validation_Rule_Id){
            errorArray.ValidationRule.push(index);
          }
        });
          this.errorArrayLead = errorArray;
          if(errorArray.vvField.length || errorArray.ValidationRule.length){
            return false;
          } else {
            return true;
          }
    },
    updateLeadFieldMapValues(index){
        let crmSelected = [];
        this.leadMapCRMAvailableFields.forEach(element => {
          if(element.CurrentRowValues.VV_Field_Id){
            crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
          }
        });
        this.leadCrmSelected = crmSelected;
          if(this.leadMapCRMAvailableFields[index].CurrentRowValues.VV_Field_Id){
          this.leadMapCRMAvailableFields[index].CurrentRowValues.Overwrite = true;
          if(this.leadMapCRMAvailableFields[index].Available_Validation_Rules.length == 1){
            this.leadMapCRMAvailableFields[index].CurrentRowValues.Validation_Rule_Id =this.leadMapCRMAvailableFields[index].Available_Validation_Rules[0].Key;
          }
        } else {
          this.leadMapCRMAvailableFields[index].CurrentRowValues.Validation_Rule_Id = ''
          this.leadMapCRMAvailableFields[index].CurrentRowValues.Overwrite = false;
        }
        this.leadMapCRMAvailableFields[index].CurrentRowValues.Default_Value = ''
    },
    async saveLeadMapSettings(){
        let res = this.checkLeadMapValues();
          let ans = false;
      if(this.keyFieldLead && res){
        ans = true;
      } else {
        if (!this.keyFieldLead) {
        ans = false;
          this.$vs.notify({
            title: "Erorr",
            text: "Please select one field as a id field",
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        }
      }
        if(res && ans){
          let temp = this.leadMapCRMAvailableFields;
          let crv = [];
          temp.forEach(element => {
            if(element.CurrentRowValues.VV_Field_Id){
              crv.push({
                "VV_Field_Id": element.CurrentRowValues.VV_Field_Id.VV_Field_Id,
                "Field_API_Name": element.Field_API_Name,
                "Field_Display_Name": "string",
                "Is_Key_Field": element.Required_Field,
                "Is_Update_Field": element.Field_API_Name == this.keyFieldLead? true: false,
                "Validation_Rule_Id": element.CurrentRowValues.Validation_Rule_Id,
                "Default_Value": element.CurrentRowValues.Default_Value,
                "Overwrite": element.CurrentRowValues.Overwrite,
              });
            }
          });
          this.$vs.loading();
          let input = {
            CurrentRowValues:crv,
            Do_Not_Update_Blank:this.leadMapUpdateBlankFields,
            Connection_Id:this.integrationData.Connection_ID
          };
          await this.axios.post("/ws/IntegrationPartnerLeadMap/SaveSettings", input)
          .then((response) => {
            let data = response.data;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.getContactMapSettings();
            window.scrollTo(0, 0);
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      }
    },
    async getPageListGlobalHandling(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerGlobalLeadHandlingSettings/GetPageLists?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.accountText = data.Account_Text;
            this.contactText = data.Contact_Text;
            this.leadText = data.Lead_Text;
            this.userList = data.User_List;
            this.importingIntoSystemList = data.Importing_Into_System_List;
            this.exportingIntoSystemList = data.Exporting_Into_System_List;
            this.handlingDuplicateList = data.Handling_Duplicate_List;
            this.doNotImportList = data.Do_Not_Import_List;
            this.getGlobalHandlingSetting();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getGlobalHandlingSetting(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerGlobalLeadHandlingSettings/GetSettings?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.defaultAccountOwnerUserId = data.Default_Account_Owner_User_Id;
            this.defaultContactOwnerUserId = data.Default_Contact_Owner_User_Id;
            this.defaultLeadOwnerUserId = data.Default_Lead_Owner_User_Id;
            this.importToCRMAccountOption = data.ImportToCRM_Account_Option;
            this.importToCRMContactOption = data.ImportToCRM_Contact_Option;
            this.importToCRMLeadOption = data.ImportToCRM_Lead_Option;
            this.importToCRMDuplicateHandlingOption = data.ImportToCRM_Duplicate_Handling_Option;
            this.exportToCRMAccountOption = data.ExportToCRM_Account_Option;
            this.exportToCRMContactOption = data.ExportToCRM_Contact_Option;
            this.exportToCRMLeadOption = data.ExportToCRM_Lead_Option;
            this.doNotImport = data.Do_Not_Import;
            this.importNotificationEmail = data.Import_Notification_Emails;
            this.exportNotificationEmail = data.Export_Notification_Emails;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async saveGlobalLeadHandlingSettings(){
      if(this.validateMultipleEmailsCommaSeparated() && this.validateMultipleEmailsCommaSeparatedExport()){
          this.$vs.loading();
          let input = {
            Connection_Id:this.integrationData.Connection_ID,
            "Default_Account_Owner_User_Id": this.defaultAccountOwnerUserId,
            "Default_Contact_Owner_User_Id": this.defaultContactOwnerUserId,
            "Default_Lead_Owner_User_Id": this.defaultLeadOwnerUserId,
            "ImportToCRM_Account_Option": this.importToCRMAccountOption,
            "ImportToCRM_Contact_Option": this.importToCRMContactOption,
            "ImportToCRM_Lead_Option": this.importToCRMLeadOption,
            "ImportToCRM_Duplicate_Handling_Option": this.importToCRMDuplicateHandlingOption,
            "Do_Not_Import": this.doNotImport,
            "ExportToCRM_Account_Option": this.exportToCRMAccountOption,
            "ExportToCRM_Contact_Option": this.exportToCRMContactOption,
            "ExportToCRM_Lead_Option": this.exportToCRMLeadOption,
            Import_Notification_Emails:this.importNotificationEmail,
            Export_Notification_Emails:this.exportNotificationEmail
          };
          await this.axios.post("/ws/IntegrationPartnerGlobalLeadHandlingSettings/SaveSettings", input)
          .then((response) => {
            let data = response.data;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.getGlobalHandlingSetting();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
      }
    },
    async getListsModuleLeadHandling(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerModuleLeadHandlingSettings/GetLists?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.moduleCrmName = data.CRM_Name;
            this.classificationsOptions = data.Classifications;
            this.recordListOptions = data.Record_Options;
            this.getModuleLeadHandlingSetting();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getModuleLeadHandlingSetting(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerModuleLeadHandlingSettings/GetSettings?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.allowQuickExportLooking = data.Allow_QuickExport_Looking;
            this.formCaptureOptionId = data.FormCapture_Option_Id;
            this.formCaptureClassificationId = data.FormCapture_Classification_Id;
            this.formCaptureInsertNote = data.FormCapture_Insert_Note;
            this.webVisitorOptionEnabled = data.WebVisitor_Option_Enabled;
            this.webVisitorOptionVisitThreshold = data.WebVisitor_Note_Option_Visit_Threshold;
            this.webVisitorOptionVisitPeriod = data.WebVisitor_Note_Option_Visit_Period;
            this.webVisitorOptionId = data.WebVisitor_Option_Id;
            this.webVisitorClassificationId = data.WebVisitor_Classification_Id;
            this.webVisitorInsertNote = data.WebVisitor_Insert_Note;
            this.webVisitorNoteOptionVisitThreshold = data.WebVisitor_Note_Option_Visit_Threshold;
            this.webVisitorNoteOptionVisitPeriod = data.WebVisitor_Note_Option_Visit_Period;
            this.allowQuickExportShopping = data.Allow_QuickExport_Shopping;
            this.shoppingOptionEnabled = data.Shopping_Option_Enabled;
            this.shoppingOptionScoreThreshold = data.Shopping_Option_Score_Threshold;
            this.shoppingOptionScorePeriod = data.Shopping_Option_Score_Period;
            this.shoppingOptionId = data.Shopping_Option_Id;
            this.shoppingClassificationId = data.Shopping_Classification_Id;
            this.shoppingInsertNote = data.Shopping_Insert_Note;
            this.shoppingNoteOptionScoreThreshold = data.Shopping_Note_Option_Score_Threshold;
            this.shoppingNoteOptionScorePeriod = data.Shopping_Note_Option_Score_Period;
            this.allowQuickExportContact = data.Allow_QuickExport_Contact;
            this.callTrackingInsertNote = data.CallTracking_Insert_Note;
            this.lookingQuickExportOptionId = data.Looking_Quick_Export_Option_Id,
            this.lookingQuickExportClassificationId = data.Looking_Quick_Export_Classification_Id,

            this.shoppingQuickExportClassificationId = data.Shopping_Quick_Export_Classification_Id;
            this.shoppingQuickExportOptionId = data.Shopping_Quick_Export_Option_Id;

            this.contactQuickExportOptionId = data.Contact_Quick_Export_Option_Id;
            this.contactQuickExportClassificationId = data.Contact_Quick_Export_Classification_Id;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async saveSettingModuleLeadHandling(){
      this.$validator.validateAll("moduleHandlingform").then(async (resultLead) => {
        if(resultLead){
          this.$vs.loading();
          let input = {
              Connection_Id:this.integrationData.Connection_ID,
            "Allow_QuickExport_Looking": this.allowQuickExportLooking,
            "FormCapture_Option_Id": this.formCaptureOptionId,
            "FormCapture_Classification_Id": this.formCaptureClassificationId,
            "FormCapture_Insert_Note": this.formCaptureInsertNote,
            "WebVisitor_Option_Enabled": this.webVisitorOptionEnabled,
            "WebVisitor_Option_Visit_Threshold": this.webVisitorOptionVisitThreshold,
            "WebVisitor_Option_Visit_Period": this.webVisitorOptionVisitPeriod,
            "WebVisitor_Option_Id": this.webVisitorOptionId,
            "WebVisitor_Classification_Id": this.webVisitorClassificationId,
            "WebVisitor_Insert_Note": this.webVisitorInsertNote,
            "WebVisitor_Note_Option_Visit_Threshold": this.webVisitorNoteOptionVisitThreshold,
            "WebVisitor_Note_Option_Visit_Period": this.webVisitorNoteOptionVisitPeriod,
            "Allow_QuickExport_Shopping": this.allowQuickExportShopping,
            "Shopping_Option_Enabled": this.shoppingOptionEnabled,
            "Shopping_Option_Score_Threshold": this.shoppingOptionScoreThreshold,
            "Shopping_Option_Score_Period": this.shoppingOptionScorePeriod,
            "Shopping_Option_Id": this.shoppingOptionId,
            "Shopping_Classification_Id": this.shoppingClassificationId,
            "Shopping_Insert_Note": this.shoppingInsertNote,
            "Shopping_Note_Option_Score_Threshold": this.shoppingNoteOptionScoreThreshold,
            "Shopping_Note_Option_Score_Period": this.shoppingNoteOptionScorePeriod,
            "Allow_QuickExport_Contact": this.allowQuickExportContact,
            "CallTracking_Insert_Note": this.callTrackingInsertNote,
              Looking_Quick_Export_Classification_Id: this.lookingQuickExportClassificationId,
              Looking_Quick_Export_Option_Id: this.lookingQuickExportOptionId,
              Shopping_Quick_Export_Classification_Id: this.shoppingQuickExportClassificationId,
              Shopping_Quick_Export_Option_Id: this.shoppingQuickExportOptionId,
              Contact_Quick_Export_Classification_Id: this.contactQuickExportClassificationId,
              Contact_Quick_Export_Option_Id: this.contactQuickExportOptionId
          };
          await this.axios.post("/ws/IntegrationPartnerModuleLeadHandlingSettings/SaveSettings", input)
          .then((response) => {
            let data = response.data;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.getGlobalHandlingSetting();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });

          }
        });
    },
    async getValidationRules(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerValidationRules/GetValidationRules?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            this.validationRulesList = data;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getValidationRulesListData(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerValidationRuleDetail/GetListData?connection_Id="+this.integrationData.Connection_ID)
          .then(response => {
            let data = response.data;
            let newArr = [];
            data.forEach(element => {
              element.Field_Type.Validation_Type_List = element.Validation_Type_List;
              newArr.push(element.Field_Type);
            });
            this.validationFieldTypeList = newArr;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    async getValidationRuleDetails(id){
          this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerValidationRuleDetail/GetSettings?validation_Id="+id)
          .then(response => {
            let data = response.data;
            this.validationRuleId = data.Validation_Rule_Id;
            this.validationConversionList = data.ConversionList;
            this.validationRuleName = data.Validation_Rule_Name;
            this.validationFieldType = this.validationFieldTypeList.find( ({ Key }) => Key === data.Field_Type_Id );
            this.validationType = data.Validation_Type_Id;
            this.validationrulesPopup = true;
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    }, 
    async saveSettingValidationRule(){
          this.$validator.validateAll("validationRuleForm").then(async (result) => {
          if(result) {
          this.$vs.loading();
          let input = {
            "Validation_Rule_Id": this.validationRuleId,
            "Validation_Rule_Name": this.validationRuleName,
            "Field_Type_Id": this.validationFieldType?this.validationFieldType.Key:0,
            "Validation_Type_Id": this.validationType?this.validationType:0,
            "ConversionList": this.validationConversionList
          };
          await this.axios.post("/ws/IntegrationPartnerValidationRuleDetail/SaveSettings", input)
          .then((response) => {
            let data = response.data;
            this.validationrulesPopup = false;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
              this.cancelValidationRule();
            this.getValidationRulesListData();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
        }
      });
    },
    removeItemFromCoversionList(){
      if(this.conversionListSelected){
        let index = this.validationConversionList.findIndex(el => el === this.conversionListSelected);
        this.validationConversionList.splice(index, 1);
      } else {
        this.conversionRemoveWarning = true;
      }
    },
    cancelAddItem(){
      this.fullWord = null;
      this.shortWord = null;
      this.conversionPopup = false;
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    addItemToConversionList(){
      this.$validator.validateAll("conversionText").then(async (result) => {
        if(result){
          this.validationConversionList.push(this.fullWord+", "+this.shortWord);
          this.conversionPopup = false;
          this.fullWord = null;
          this.shortWord = null;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
        }
      });
    },
    cancelValidationRule(){
        this.validationrulesPopup = false;
        this.validationConversionList = [];
        this.validationRuleName= null;
        this.validationFieldType= null;
        this.validationType = null;
        this.validationRuleId = 0;
        setTimeout(() => {
          this.errors.clear();
        }, 100);
    },
    deleteValidationRUleConfirmation(id){
      this.validationdeletePopup = true;
      this.validationRuleId = id;
    },
    async deleteValidationRules(){
        this.$vs.loading();
          await this.axios.get("/ws/IntegrationPartnerValidationRules/DeleteValidationRule?validation_Rule_Id="+this.validationRuleId)
          .then(response => {
            let data = response.data;
              this.$vs.notify({
              title: "Success",
              text: data.Message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000
              });
            this.validationdeletePopup = false;
            this.validationRuleId = 0;
            this.getValidationRules();
            this.$vs.loading.close();
          })
          .catch(e => {
            this.$vs.loading.close();
            this.showError(e);
          });
    },
    disconnectCRM(){
      this.$vs.loading();
      this.axios
      .get("/ws/IntegrationPartnerConnection/DeleteCRMIntegrationConnection?connection_Id=" +this.integrationData.Connection_ID)
      .then((response) => {
        let data = response.data;
        if (data.Transaction_Successful) {
          this.getConnectionStatus();
          this.$vs.notify({
            title: "Success",
            text: "Connection removed!",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Connection not removed!",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    async connectCRM(){
      this.$vs.loading();
      await this.axios
      .get("/ws/IntegrationPartnerConnection/GetExternalLinkForAuthUpdate?connection_Id=" +this.integrationData.Connection_ID)
      .then((response) => {
        let data = response.data;
        this.isEventListner = true;
        window.open(data,+"Zoho Account","toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100").focus();
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    addValueToRule(){
      this.ruleGroupInputValueMultiple.push(this.ruleGroupInputValue);
      this.ruleGroupInputValue = '';
    },
    removeRuleSelectedValue(){
      const index = this.ruleGroupInputValueMultiple.indexOf(this.ruleSeleted);
      this.ruleGroupInputValueMultiple.splice(index,1);
      this.ruleSeleted =null;
    },
    //step -4 change module name 
    changeModule(){
          if(this.crmLogicFilter.Rule_Group.length && this.crmLogicFilter.Rule_Group[0].Crm_Api_Name){
            this.warningModuleChange = true;
          } 
    },
    yesChangeModule(){
        this.oldModule = this.moduleId;
          this.crmLogicFilter.Rule_Group =  [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }]
      this.warningModuleChange = false;
    },
    cancelModuleChange(){
        this.oldModule = this.moduleId;
        this.warningModuleChange = false;
    },
    async getTaskScheduler(){
      this.$vs.loading();
      await this.axios
      .get(
        "/ws/IntegrationSchedule/ListIntegrationSchedules?connectionID=" +this.integrationData.Connection_ID
      )
      .then((response) => {
        let data = response.data;
        this.listTaskSchedule = data;
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
     async getListOfScheduleJobActivities(){
      this.$vs.loading();
      await this.axios
      .get(
        "/ws/IntegrationSchedule/ListActivities?connectionID=" +this.integrationData.Connection_ID
      )
      .then((response) => {
        let data = response.data;
        this.scheduleJobActivityList = data;
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    addEditIntegrationSchedule(){
      this.$validator.validateAll("scheduleIntegration").then((result) => {
        if(result){
                  this.$vs.loading();
      
        let activity = this.scheduleJobActivityList.find( ({ ActivityId }) => ActivityId === this.scheduleActivity );
        let input={
          "Id": this.scheduleId,
          "ConnectionId": this.integrationData.Connection_ID,
          "Schedule_Name": this.scheduleName,
          "Activity_Name": activity?activity.Activity_Name:"",
          "ActivityId": this.scheduleActivity?this.scheduleActivity:0,
          "Run_Once_Date": moment().format(),
          "Recurrence_Pattern": this.recurrencePattern,
          "Recur_DaysOfWeek": this.recurrencePattern =='weekly'?this.recurDaysOfWeek:null,
          "Recur_DayofMonth": this.recurrencePattern =='monthly'?this.recurDayOfMonth:0,
          "TimeHour": parseInt(moment(this.startTime, "H:m").format("hh")),
          "TimeMinute": parseInt(moment(this.startTime, "H:m").format("m")),
          "AMPM": moment(this.startTime, "H:m").format("A")
        };
        let url = this.scheduleId != 0?"/ws/IntegrationSchedule/EditIntegrationSchedule":"/ws/IntegrationSchedule/AddIntegrationSchedule";
        this.axios.post(url, input).then(() => {
          this.scheduleCancel();
          this.getTaskScheduler();
          this.$vs.notify({
            title: "Success",
            text: "Queue saved successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
        }).catch(e => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000
            });
          });
      }
      });     
    },
    scheduleCancel(){
      this.schedulePopup = false; 
      this.scheduleId = 0;
      this.scheduleName = "";
      this.recurrencePattern = "once";
      this.scheduleName = null;
      this.recurDaysOfWeek = [];
      this.numberOfRecur = null;
      this.scheduleActivity = null;
      this.recurDayOfMonth = null;
      this.startTime = null;
       setTimeout(() => {
                this.errors.clear();
              }, 100);
    },
    async editGetIntegrationScheduleData(id){
      this.$vs.loading();
      await this.axios
      .get(
        "/ws/IntegrationSchedule/GetIntegrationSchedule?scheduleID="+id)
      .then((response) => {
        let data = response.data;
        this.scheduleId = data.Id;
        this.scheduleName = data.Schedule_Name;
        this.recurrencePattern = data.Recurrence_Pattern;
        this.startTime = this.convertTime12to24(data.TimeHour+":"+data.TimeMinute+" "+data.AMPM);
        this.recurDaysOfWeek = data.Recur_DaysOfWeek;
        this.recurDayOfMonth = data.Recur_DayofMonth;
        this.scheduleActivity = data.ActivityId;
        this.schedulePopup = true;
        this.$vs.loading.close();
      })
      .catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    convertTime12to24(time12h) {
      const [time, modifier] = time12h.split(' ');
      let [hours, minutes] = time.split(':');
      if (hours === '12') {
          hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}:${minutes}`;
    },
    async deleteTaskSchedule(){
      this.$vs.loading();
      await this.axios.post("/ws/IntegrationSchedule/DeleteIntegrationSchedule?Id="+this.scheduleId)
        .then((response) => {
          this.scheduleId = 0;
          this.validationdeletePopup = false;
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
          this.getTaskScheduler();
          this.$vs.loading.close();
        }).catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
      });
    },
    validateEmail(field) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (re.test(field)) ? true : false;
    },
    validateMultipleEmailsCommaSeparated() {
      if(this.importNotificationEmail){
        var result = this.importNotificationEmail.split(",");
        for(var i = 0;i < result.length;i++)
        if(!this.validateEmail(result[i])) 
              return false;           
        return true;
      } else {
        return true;
      }
    },
    validateMultipleEmailsCommaSeparatedExport() {
      if(this.exportNotificationEmail){
        var result = this.exportNotificationEmail.split(",");
        for(var i = 0;i < result.length;i++)
        if(!this.validateEmail(result[i])) 
              return false;           
        return true;
      } else {
        return true;
      }
    }
  },
};
</script>